import { TAU } from '../engine/utils/constants';
import { PLANET_LIST } from './planets';
import { DefaultPlanet } from './planets/default';
import { PrototypeGame } from './prototype-game';
import { GameScreen } from './screens/game-screen';
import { LobbyScreen } from './screens/lobby-screeen';
import { WelcomeScreen } from './screens/welcome-screen';
import { STAR_LIST } from './stars';
import { DefaultStar } from './stars/default';
import { NorthStar } from './stars/north';
import { TECHNOLOGY_LIST } from './technologies';

export const PROTOTYPE_DEFINITION = {
    playerData: {
        schema: undefined,

        base: {
            selectedSet: makeGameSet(),
            savedSets: [ makeGameSet(), makeGameSet(), makeGameSet() ]
        }
    },

    Game: PrototypeGame,

    client: {
        virtualWidth: 1600,
        virtualHeight: 900,
        backgroundColor: 'rgb(200,200,200)',
        autoLogin: true,
        autoSwitchScreen: true,
        screens: {
            welcome: WelcomeScreen,
            lobby: LobbyScreen,
            game: GameScreen
        },
        onStart(client) {
            let size = 256;

            for (let body of [...STAR_LIST, ...PLANET_LIST, DefaultPlanet, DefaultStar, NorthStar]) {
                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                canvas.width = size;
                canvas.height = size;

                ctx.arc(size/2, size/2, size/2, 0, TAU);
                ctx.fillStyle = body.color;
                ctx.fill();

                client.registerImage(`${body.id}.png`, canvas);
            }

            for (let technology of TECHNOLOGY_LIST) {
                let a = 0;
                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                canvas.width = size;
                canvas.height = size;

                ctx.fillStyle = technology.color;
                ctx.fillRect(a, a, size - 2 * a, size - 2 * a);

                client.registerImage(`${technology.id}.png`, canvas);
            }
        }
    }
}

function makeGameSet() {
    return {
        starId: null,
        planetId: null,
        technologyId: null
    };
}