import { REST_RATIO } from '../game-constants';
import { Star } from './star';

const INNER_POPULATION_GROWTH = 30;
const OUTER_POPULATION_GROWTH = 15;

export class Yot extends Star {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'yot'
    static color = 'black'
    static description = `+${INNER_POPULATION_GROWTH} population per turn to inner planets. +${OUTER_POPULATION_GROWTH} population per turn to outer planets.`;

    getPlanetPopulationGrowth({ planet }) {
        if (this.isStarOf(planet)) {
            let ratio = planet.order.type === 'rest' ? REST_RATIO : 1;

            if (planet.ring.index === 0) {
                return INNER_POPULATION_GROWTH * ratio;
            } else {
                return OUTER_POPULATION_GROWTH * ratio;
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Yot);