import { Technology } from './technology';

const DEFEND_STRENGTH_MULTIPLIER = 3;

export class IronWill extends Technology {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'iron-will';
    static color = 'lightsteelblue';
    static description = `Defense orders triple the population's strength instead of doubling it.`;

    getDefenseStrengthMultiplier({ planet }) {
        if (planet.owner === this.owner) {
            return DEFEND_STRENGTH_MULTIPLIER;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(IronWill);