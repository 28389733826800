import { Planet } from './planet';

const STARTING_POPULATION = 30;

export class Atov extends Planet {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'atov';
    static color = 'black';
    static description = `Starts with +${STARTING_POPULATION} population.`;

    onGameStart() {
        this.population += STARTING_POPULATION;
    }
}
globalThis.ALL_FUNCTIONS.push(Atov);