export const TEXTBOX_STYLE = {
    backgroundColor: 'white',
    borderColor: 'black',
    borderRadius: '15%',
    borderWidth: '4%',
    textHorizontalAlign: 'left',
    textSize: '50%',
    textMargin: '5%',
};

export const LABEL_STYLE = {
    textSize: '95%',
    textHorizontalAlign: 'center'
};

export const BIG_BUTTON_STYLE = {
    backgroundColor: 'orange',
    borderColor: 'black',
    borderRadius: TEXTBOX_STYLE.borderRadius,
    borderWidth: TEXTBOX_STYLE.borderWidth,
    textHorizontalAlign: 'center',
    textSize: '65%',
};

export const SMALL_BUTTON_STYLE = {
    backgroundColor: 'black',
    borderRadius: TEXTBOX_STYLE.borderRadius,
    borderWidth: TEXTBOX_STYLE.borderWidth,
    textHorizontalAlign: 'center',
    textSize: '70%',
    textColor: 'white'
};

export const PLACEHOLDER_COLOR = 'grey';