import { Screen } from '../../engine/screen';
import { BACKGROUND_COLOR, GAME_NAME, GAME_NAME_COLOR } from '../visual-constants';
import { Button } from '../../engine/widgets/button';
import { Label } from '../../engine/widgets/label';
import { TextBox } from '../../engine/widgets/textbox';
import { BIG_BUTTON_STYLE, LABEL_STYLE, TEXTBOX_STYLE } from '../styles';
import { USERNAME_REGEXP } from '../validation';
import { layout } from '../../engine/layout/layout';

export class WelcomeScreen extends Screen {
    constructor(client) {
        super(client);

        let { gameName, username, password, authenticate } = layout([1600, 900], WINDOW_LAYOUT);

        this.widgets = {
            gameName: new Label({
                box: gameName,
                text: GAME_NAME,
                style: {
                    ...LABEL_STYLE,
                    textColor: GAME_NAME_COLOR
                }
            }),
            username: new TextBox({
                box: username,
                placeholder: 'Username',
                text: '',
                style: TEXTBOX_STYLE,
                onEnter: () => this._authenticate()
            }),
            password: new TextBox({
                box: password,
                placeholder: 'Password',
                isPassword: true,
                disabled: true,
                style: TEXTBOX_STYLE,
                onEnter: () => this._authenticate()
            }),
            authenticate: new Button({
                box: authenticate,
                text: 'Log in',
                style: BIG_BUTTON_STYLE,
                onClick: () => this._authenticate()
            })
        };

        this.client.on({
            keyDown(evt) {
                if (evt.code === 'Tab') {
                    let widgets = Object.values(this.widgets);

                    evt.preventDefault();
                    if (evt.shiftKey) {
                        this.client.changeFocus(widgets, -1);
                    } else {
                        this.client.changeFocus(widgets, 1);
                    }
                }
                // else if (evt.code === 'Escape') {
                    // this.client.click(null);
                // }
            }
        }, this);
        this.client.focus(this.widgets.username);
    }

    _authenticate() {
        let username = this.widgets.username.text;
        let password = this.widgets.password.text;

        if (USERNAME_REGEXP.test(username)) {
            return this.client.authenticate({ username, password });
        }
    }

    update() {
        let username = this.widgets.username.text;
        let disableAuthenticateButton = !USERNAME_REGEXP.test(username);
        let renderableObjects = Object.values(this.widgets);

        this.widgets.authenticate.disabled = disableAuthenticateButton;

        this.client.render(renderableObjects);
    }
}

const WINDOW_LAYOUT = [
    `column ${BACKGROUND_COLOR}`, [
        0.5,
        'line *4', [
            1,
            '#gameName ]5 *1',
            1
        ],
        'column *16', [
            1,
            'line *3', [
                1,
                'column', [
                    3,
                    '#username *20 %4',
                    // 1,
                    // '#password *20 %4',
                    // 4,
                    '#authenticate *20 %4',
                    3
                ],
                1
            ],
            1
        ],
        3
    ]
];
globalThis.ALL_FUNCTIONS.push(WelcomeScreen);