import { ENERGY_CIRCLE_DURATION} from '../game-constants';

export class PopulationIncrease {
    constructor(planet, amount) {
        this.planet = planet;
        this.amount = amount;
        this.duration = ENERGY_CIRCLE_DURATION;
    }

    render(t) {
        this.planet.bonusPopulation = this.amount * t;
    }

    onFinish() {
        this.planet.bonusPopulation = 0;
        this.planet.population += Math.ceil(this.amount);
    }
}
globalThis.ALL_FUNCTIONS.push(PopulationIncrease);