import { Technology } from './technology';

export class HelpingHands extends Technology {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'helping-hands';
    static color = 'lightgreen';
    static description = `Move orders that target allied planets have +1 range.`;

    getMoveBonusRange({ source, target }) {
        if (source.owner === this.owner && target.owner === this.owner) {
            return 1;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(HelpingHands);