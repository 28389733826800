export class ClientGameApi {
    constructor(client) {
        this.client = client;
    }

    endGame({ winner }) {

    }

    async transition(transition) {
        if (Array.isArray(transition)) {
            return this.client.playMultipleAnimations(transition);
        } else {
            return this.client.playAnimation(transition);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(ClientGameApi);