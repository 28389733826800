import { getWebSocketServerUrl } from 'outpost';
import { Client } from './engine/client';
import { PROTOTYPE_DEFINITION } from './prototype/definition';

async function main() {
    let webSocketServerUrl = getWebSocketServerUrl();
    let client = new Client(PROTOTYPE_DEFINITION, webSocketServerUrl);

    await client.start();
}

main();