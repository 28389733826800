import { Screen } from '../../engine/screen';
import { Label } from '../../engine/widgets/label';
import { BIG_BUTTON_STYLE, LABEL_STYLE, SMALL_BUTTON_STYLE } from '../styles';
import { BACKGROUND_COLOR, GAME_NAME, GAME_NAME_COLOR } from '../visual-constants';
import { isInstanceOf, isSubClassOf } from '../../engine/utils/objects';
import { PLANET_LIST } from '../planets';
import { Button } from '../../engine/widgets/button';
import { STAR_LIST } from '../stars';
import { SelectionCell } from '../selection-cell';
import { Star } from '../stars/star';
import { Technology } from '../technologies/technology';
import { Planet } from '../planets/planet';
import { TECHNOLOGY_LIST } from '../technologies';
import { layout } from '../../engine/layout/layout';

export class LobbyScreen extends Screen {
    constructor(client) {
        super(client);

        this.boxes = layout([1600, 900], this._makeWindowLayout());
        this.widgets = [
            new Label({
                box: this.boxes['game-name'],
                text: GAME_NAME,
                style: {
                    ...LABEL_STYLE,
                    textColor: GAME_NAME_COLOR
                }
            }),
            new Label({
                box: this.boxes['username'],
                text: this.client.player.username,
                style: {
                    textSize: '80%',
                    backgroundColor: 'white',
                    borderRadius: '15%'
                }
            })
        ];

        this._fillElementsToChooseFrom('available-stars', 'Stars', STAR_LIST);
        this._fillElementsToChooseFrom('available-planets', 'Planets', PLANET_LIST);
        this._fillElementsToChooseFrom('available-technologies', 'Technologies', TECHNOLOGY_LIST);

        this.playButton = new Button({
            box: this.boxes['play'],
            text: 'Find match',
            style: BIG_BUTTON_STYLE,
            onClick: () => this._toggleMatchmaking()
        });

        this.logOutButton = new Button({
            box: this.boxes['logout'],
            text: 'Log out',
            style: SMALL_BUTTON_STYLE,
            onClick: () => this._logout()
        })

        // for (let i = 0; i < 3; ++i) {
        //     this.widgets.push(new Button({
        //         box: this.boxes[`btn-load-set-${i+1}`],
        //         text: `Load set ${i+1}`,
        //         style: SMALL_BUTTON_STYLE,
        //         onClick: () => this._loadSet(this.client.player.data.savedSets[i])
        //     }));

        //     this.widgets.push(new Button({
        //         box: this.boxes[`btn-save-set-${i+1}`],
        //         text: `Save to set ${i+1}`,
        //         style: SMALL_BUTTON_STYLE,
        //         onClick: () => this._saveCurrentSet(this.client.player.data.savedSets[i])
        //     }));
        // }

        // this.widgets.push(new Button({
        //     box: this.boxes[`btn-base-set-2`],
        //     text: `Load base set`,
        //     style: SMALL_BUTTON_STYLE,
        //     onClick: () => this._loadSet(BASE_SET_1)
        // }));

        this.renderableObjects = [this.playButton, this.logOutButton, ...this.widgets];
    }

    get _player() {
        return this.client.player;
    }

    _fillElementsToChooseFrom(area, label, elements) {
        this.widgets.push(new Label({
            box: this.boxes[`label-${area}`],
            formattedText: `${label} @small{_(choose one)_}`,
            style: {
                textSize: '40%',
                textHorizontalAlign: 'center',
                textMargin: '30%'
            }
        }));

        for (let i = 0; i < elements.length; ++i) {
            let ContentClass = elements[i];

            this.widgets.push(new SelectionCell({
                parentArea: area,
                box: this.boxes[`${area}-${i}`],
                Content: ContentClass
            }));
        }
    }

    _makeWindowLayout() {
        return [
            `column ${BACKGROUND_COLOR}`, [
                0.5,
                'line *4', [
                    2,
                    '#game-name ]5 *2',
                    1,
                    'column ]10', [
                        0.4,
                        '#username *1.2',
                        0.4
                    ]
                ],
                'line *16', [
                    `column *1`, [
                        `#label-available-stars %5 ]10`,
                        `#label-available-planets %5 ]10`,
                        `#label-available-technologies %5 ]10`,
                    ],
                    `column *2`, [
                        ...makeLine('available-stars', 8),
                        ...makeLine('available-planets', 8),
                        ...makeLine('available-technologies', 8)
                    ]
                ],
                'line *3', [
                    `line *3 ]5`, [
                        `column`, [
                            `#btn-load-set-1 %4.5 ]10`,
                            `#btn-load-set-2 %4.5 ]10`,
                            `#btn-load-set-3 %4.5 ]10`,
                        ],
                        `column`, [
                            `#btn-save-set-1 %4.5 ]10`,
                            `#btn-save-set-2 %4.5 ]10`,
                            `#btn-save-set-3 %4.5 ]10`
                        ],
                        `column`, [
                            `#btn-base-set-1 %4.5 ]10`,
                            `#btn-base-set-2 %4.5 ]10`,
                            `#btn-base-set-3 %4.5 ]10`
                        ],
                    ],
                    '#play %4 ]10 *3',
                    2,
                    'column ]10 *1', [
                        1,
                        '#logout %4 *1'
                    ]
                ]
            ]
        ];
    }

    init() {
        this.client.addMouseHandler({
            permanent: true,
            action: 'click',
            button: 'left',
            isActive: () => this.client.player.activity === 'idle',
            target: {
                isValidObject: object => isInstanceOf(object, SelectionCell),
                hoverGraphics: {
                    cursorSkin: 'pointer',
                    backgroundColor: 'black',
                    backgroundAlpha: 0.3,
                },
                onComplete: object => {
                    this.widgets
                        .filter(widget => isInstanceOf(widget, SelectionCell) && widget.parentArea === object.parentArea)
                        .forEach(widget => widget.selected = false);
                    object.selected = true;
                    this._saveCurrentSet();
                }
            }
        });

        this._loadSet(this._player.data.selectedSet);

        // return this.client.startMatchmaking(); // TODO
    }

    _clearSelection() {
        this.widgets
            .filter(widget => isInstanceOf(widget, SelectionCell))
            .forEach(widget => widget.selected = false);
    }

    _saveCurrentSet(set = this.client.player.data.selectedSet) {
        set.starId = this.widgets.find(widget => isInstanceOf(widget, SelectionCell) && isSubClassOf(widget.Content, Star) && widget.selected)?.Content.id || null;
        set.planetId = this.widgets.find(widget => isInstanceOf(widget, SelectionCell) && isSubClassOf(widget.Content, Planet) && widget.selected)?.Content.id || null;
        set.technologyId = this.widgets.find(widget => isInstanceOf(widget, SelectionCell) && isSubClassOf(widget.Content, Technology) && widget.selected)?.Content.id || null;
        this.client.savePlayerData();
    }

    _loadSet(set) {
        let { starId, planetId, technologyId } = set;

        this._clearSelection();

        let starCell = this.widgets.find(widget => isInstanceOf(widget, SelectionCell) && widget.Content.id === starId);
        let planetCell = this.widgets.find(widget => isInstanceOf(widget, SelectionCell) && widget.Content.id === planetId);
        let technologyCell = this.widgets.find(widget => isInstanceOf(widget, SelectionCell) && widget.Content.id === technologyId);

        if (starCell) {
            starCell.selected = true;
        }

        if (planetCell) {
            planetCell.selected = true;
        }

        if (technologyCell) {
            technologyCell.selected = true;
        }

        if (set !== this.client.player.data.selectedSet) {
            this._saveCurrentSet();
        }
    }

    update() {
        let selectedSet = this._player.data.selectedSet;

        let text = 'Find match';
        let enabled = selectedSet.starId && selectedSet.planetId && selectedSet.technologyId;

        if (this.client.player.activity === 'matchmaking') {
            text = 'Cancel';
        }

        this.playButton.text = text;
        this.playButton.disabled = !enabled;

        this.client.render(this.renderableObjects);

        let { x, y, hovered } = this.client.getCursor();

        if (hovered && hovered.getTooltip) {
            let tooltip = hovered.getTooltip(this.client);

            if (tooltip) {
                this.client.renderTooltip({ x, y }, tooltip);
            }
        }
    }

    _toggleMatchmaking() {
        if (this._player.activity === 'matchmaking') {
            this.client.stopMatchmaking();
        } else {
            this.client.startMatchmaking();
        }
    }

    _logout() {
        this.client.logout();
    }
}

function makeLine(id, cellCount) {
    let line = [
        `line #${id}`,
        []
    ];

    for (let i = 0; i < cellCount; ++i) {
        line[1].push(`#${id}-${i} %1 ]10`);
    }

    return line;
}
globalThis.ALL_FUNCTIONS.push(LobbyScreen);