import { BATTLEFIELD_HEIGHT, BATTLEFIELD_WIDTH } from '../game-constants';

export class EndGameAnnouncement {
    render(client) {
        let winner = client.game.winner;

        if (!winner) {
            return null;
        }

        let victory = winner === client.player;
        let color = victory ? 'blue' : 'red';
        let text = victory ? 'Victory!' : 'Defeat.';

        return {
            x: BATTLEFIELD_WIDTH / 2,
            y: BATTLEFIELD_HEIGHT / 2,
            width: BATTLEFIELD_WIDTH / 3,
            height: BATTLEFIELD_HEIGHT,
            aspectRatio: 6,
            text: text,
            textSize: '80%',
            textColor: color,
            backgroundColor: 'white',
            borderRadius: '15%',
            borderWidth: '3%',
            borderColor: color
        };
    }
}
globalThis.ALL_FUNCTIONS.push(EndGameAnnouncement);