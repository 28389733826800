import { getPointOnQuadraticCurve, getQuadraticCurveControlPoint } from '../../engine/utils/geometry';
import { computeDistance, getRandomFloat } from '../../engine/utils/math';

export class PopulationMove {
    constructor({ source, target, strength }) {
        this.source = source;
        this.target = target;
        this.strength = strength;
        this.m = getRandomFloat(-0.20, 0.20);
        this.duration = computeDistance(source.getPosition(), target.getPosition()) / 400 * getRandomFloat(1, 1.3);
        this.delay = getRandomFloat(0, 0.5);
    }

    render(t, client) {
        let color = this.source.owner === client.player ? 'blue' : 'red';
        let p1 = this.source.getPosition(client.data.mirror);
        let p2 = this.target.getPosition(client.data.mirror);
        let cp = getQuadraticCurveControlPoint(p1, p2, this.m);
        let { x, y } = getPointOnQuadraticCurve(p1, p2, cp, t);

        return {
            area: 'space',
            x,
            y,
            width: 7,
            height: 7,
            shape: 'circle',
            backgroundColor: color
        };
    }

    onStart() {
        this.source.population -= 1;
    }

    onFinish() {
        let source = this.source;
        let target = this.target;

        // Special case if the target is neutral, we always expect it to have 0 population in this case
        if (!target.owner) {
            if (!target.tmpOwner || target.tmpOwner === source.owner) {
                target.population += 1;
                target.tmpOwner = source.owner;
            } else {
                target.population -= 1;
                if (target.population === 0) {
                    target.tmpOwner = null;
                }
            }
        } else if (target.owner === source.owner) {
            target.population += 1;
            
            if (target.population >= 0) {
                target.tmpOwner = target.owner;
            }
        } else {
            let virtualDmg = Math.min(target.virtualPopulation, this.strength * target.virtualDmgRatio);
            let realDmg = this.strength - virtualDmg;

            target.virtualPopulation -= virtualDmg;
            target.population -= realDmg;

            if (target.population < 0) {
                target.tmpOwner = source.owner;
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(PopulationMove);