// Represents a rectangle of center (x,y) and of dimensions (width,height)
export class Box {
    constructor(x, y, width, height) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    static null() {
        return new Box(0, 0, 0, 0);
    }

    static from(value) {
        if (value instanceof Box) {
            return value.clone();
        } else if (Array.isArray(value)) {
            if (value.length === 2) {
                return new Box(value[0] / 2, value[1] / 2, value[0], value[1]);
            } else {
                return new Box(value[0], value[1], value[2], value[3]);
            }
        } else if (value) {
            return new Box(value.x ?? value.width / 2, value.y ?? value.height / 2, value.width, value.height);
        } else {
            return Box.null();
        }
    }

    static fromTopLeftAndSize(x1, y1, width, height) {
        return new Box(x1 + width / 2, y1 + height / 2, width, height);
    }

    get x1() {
        return this.x - this.width / 2;
    }

    get y1() {
        return this.y - this.height / 2;
    }

    get x2() {
        return this.x + this.width / 2;
    }

    get y2() {
        return this.y + this.height / 2;
    }

    round() {
        let newX = Math.round(this.x1);
        let newY = Math.round(this.y1);
        let dx = this.x1 - newX;
        let dy = this.y1 - newY;
        let width = Math.round(this.width - dx);
        let height = Math.round(this.height - dy)

        return new Box(
            newX + width / 2,
            newY + height / 2,
            width,
            height
        );
    }

    aspectRatio() {
        return this.width / this.height;
    }

    equals(box) {
        return box
            && this.x === box.x
            && this.y === box.y
            && this.width === box.width
            && this.height === box.height;
    }

    clone() {
        return new Box(this.x, this.y, this.w, this.h);
    }

    containsPoint(pos, margin = 0) {
        return pos.x >= this.x1 - margin
            && pos.x < this.x2 + margin
            && pos.y >= this.y1 - margin
            && pos.y < this.y2 + margin;
    }

    getLocalPos(pos) {
        return {
            x: pos.x - this.x1,
            y: pos.y - this.y1
        };
    }

    isNull() {
        return this.width === 0 && this.height === 0;
    }
    
    add(box) {
        return {
            x: this.x + (box.x || 0),
            y: this.y + (box.y || 0),
            w: this.width + (box.width || 0),
            h: this.height + (box.height || 0)
        };
    }

    padToMatchAspectRatio(aspectRatio) {
        if (!aspectRatio) {
            return this;
        }

        let widthFromHeight = this.height * aspectRatio;
        let heightFromWidth = this.width / aspectRatio;
        let widthToPad = 0;
        let heightToPad = 0;

        if (this.width < widthFromHeight) {
            widthToPad = widthFromHeight - this.width;
        } else {
            heightToPad = heightFromWidth - this.height;
        }

        return this.pad(widthToPad, heightToPad);
    }

    stripToMatchAspectRatio(aspectRatio) {
        if (!aspectRatio) {
            return this;
        }

        let widthFromHeight = this.height * aspectRatio;
        let heightFromWidth = this.width / aspectRatio;
        let widthToStrip = 0;
        let heightToStrip = 0;

        if (this.width > widthFromHeight) {
            widthToStrip = this.width - widthFromHeight;
        } else {
            heightToStrip = this.height - heightFromWidth;
        }

        return this.strip(widthToStrip, heightToStrip);
    }

    isTooHorizontalForAspectRatio(aspectRatio) {
        let widthFromHeight = this.height * aspectRatio;

        return this.width > widthFromHeight;
    }

    isTooVerticalForAspectRatio(aspectRatio) {
        return !this.isTooHorizontalForAspectRatio(aspectRatio);
    }

    strip(width, height) {
        return this.pad(-width, -height);
    }

    pad(width, height) {
        return new Box(
            this.x,
            this.y,
            this.width + width,
            this.height + height
        );
    }

    stripRatio(ratio) {
        let marginToStrip = Math.round(Math.min(this.width, this.height) * ratio) * 2;

        return this.strip(marginToStrip, marginToStrip);
    }

    scale(ratio) {
        let widthToAdd = this.width * (ratio - 1);
        let heightToAdd = this.height * (ratio - 1);

        return new Box(
            this.x,
            this.y,
            this.width + widthToAdd,
            this.height + heightToAdd
        );
    }

    fullScale(ratio) {
        return new Box(
            this.x * ratio,
            this.y * ratio,
            this.width * ratio,
            this.height * ratio
        );
    }
}
globalThis.ALL_FUNCTIONS.push(Box);