import { FlexBuffer } from '../utils/flex-buffer';
import { ARRAY, BUFFER, FALSE, FUNCTION, MAP, NULL, NUMBER, OBJECT, SET, STRING, TRUE } from './constants';

export class Deserializer {
    constructor() {
        this._rootObject = null;
        this._buffer = null;
        this._idToValue = new Map();
    }

    static idToStaticResource = new Map();
    static staticResourceIdCounter = 1;

    static registerStaticResources(resources) {
        for (const resource of resources) {
            this.idToStaticResource.set(this.staticResourceIdCounter++, resource);
        }
    }

    static clearStaticResources() {
        this.idToStaticResource.clear();
        this.staticResourceIdCounter = 1;
    }

    deserialize(buffer) {
        this._idToValue.clear();
        if (buffer instanceof FlexBuffer) {
            this._buffer = buffer;
        } else {
            this._buffer = new FlexBuffer(buffer);
        }

        return this._readValue();
    }

    _readString() {
        let id = this._buffer.readUint32();
        let value = this._idToValue.get(id);

        if (value === undefined) {
            value = this._buffer.readString();
            this._idToValue.set(id, value);
        }

        return value;
    }

    _readArray() {
        let id = this._buffer.readUint32();
        let value = this._idToValue.get(id);

        if (value === undefined) {
            let length = this._buffer.readUint32();
            value = new Array(length);
            this._idToValue.set(id, value);

            for (let i = 0; i < length; ++i) {
                let item = this._readValue();
                value[i] = item;
            }
        }

        return value;
    }

    _readObject() {
        let id = this._buffer.readUint32();
        let value = this._idToValue.get(id);

        if (value === undefined) {
            let classIndex = this._buffer.readUint16();
            let classObject = this.constructor.idToStaticResource.get(classIndex);
            let size = this._buffer.readUint16();
            value = classObject ? Object.create(classObject.prototype) : {};
            this._idToValue.set(id, value);

            for (let i = 0; i < size; ++i) {
                let key = this._readString();
                let item = this._readValue();

                value[key] = item;
            }
        }

        return value;
    }

    _readFunction() {
        let index = this._buffer.readUint16();

        return this.constructor.idToStaticResource.get(index);
    }

    _readBuffer() {
        let id = this._buffer.readUint32();
        let value = this._idToValue.get(id);

        if (value === undefined) {
            let byteSize = this._buffer.readUint32();
            value = new Uint8Array(byteSize);
            this._idToValue.set(id, value);

            for (let i = 0; i < byteSize; ++i) {
                value[i] = this._buffer.readUint8();
            }
        }

        return value;
    }

    _readValue() {
        let typeId = this._buffer.readUint8();

        if (typeId === NULL) {
            return null;
        } else if (typeId === TRUE) {
            return true;
        } else if (typeId === FALSE) {
            return false;
        } else if (typeId === NUMBER) {
            return this._buffer.readFloat32();
        } else if (typeId === STRING) {
            return this._readString();
        } else if (typeId === ARRAY) {
            return this._readArray();
        } else if (typeId === OBJECT) {
            return this._readObject();
        } else if (typeId === FUNCTION) {
            return this._readFunction();
        } else if (typeId === BUFFER) {
            return this._readBuffer();
        } else if (typeId === SET) {
            return new Set(this._readArray());
        } else if (typeId === MAP) {
            return new Map(this._readArray());
        } else {
            return undefined;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Deserializer);