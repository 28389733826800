import { FlexBuffer } from '../utils/flex-buffer';
import { ARRAY, BUFFER, FALSE, FUNCTION, MAP, NULL, NUMBER, OBJECT, SET, STRING, TRUE, UNDEFINED } from './constants';

export class Serializer {
    constructor() {
        this._rootObject = null;
        this._valueToId = new Map();
        this._idCounter = 1;
        this._buffer = new FlexBuffer();
    }

    static staticResourceToId = new Map();
    static staticResourceIdCounter = 1;

    static registerStaticResources(resources) {
        for (const resource of resources) {
            this.staticResourceToId.set(resource, this.staticResourceIdCounter++);
        }
    }

    static clearStaticResources() {
        this.staticResourceToId.clear();
        this.staticResourceIdCounter = 1;
    }

    serialize(object) {
        this._buffer.reset();
        this._idCounter = 1;
        this._pushValue(object);
        this._valueToId.clear();

        return this._buffer.sliceBuffer();
    }

    _pushString(string) {
        let id = this._valueToId.get(string);

        if (!id) {
            id = this._idCounter++;
            this._valueToId.set(string, id);
            this._buffer.pushUint32(id);
            this._buffer.pushString(string);
        } else {
            this._buffer.pushUint32(id);
        }
    }

    _pushArray(array) {
        let id = this._valueToId.get(array);

        if (!id) {
            id = this._idCounter++;
            this._valueToId.set(array, id);
            this._buffer.pushUint32(id);
            this._buffer.pushUint32(array.length);
            
            for (let item of array) {
                this._pushValue(item);
            }
        } else {
            this._buffer.pushUint32(id);
        }
    }

    _pushObject(object) {
        let id = this._valueToId.get(object);

        if (!id) {
            id = this._idCounter++;
            this._valueToId.set(object, id);

            let classIndex = this.constructor.staticResourceToId.get(object.constructor) || 0;
            let entries = Object.entries(object);

            this._buffer.pushUint32(id);
            this._buffer.pushUint16(classIndex);
            this._buffer.pushUint16(entries.length);

            for (let [key, value] of entries) {
                this._pushString(key);
                this._pushValue(value);
            }

            if (classIndex === 1) {
                this._rootObject = object;
            }
        } else {
            this._buffer.pushUint32(id);
        }
    }

    _pushBuffer(buffer) {
        let id = this._valueToId.get(buffer);

        if (!id) {
            id = this._idCounter++;
            this._valueToId.set(buffer, id);
            this._buffer.pushUint32(id);
            this._buffer.pushUint32(buffer.byteLength);
            this._buffer.pushBuffer(buffer);
        } else {
            this._buffer.pushUint32(id);
        }
    }

    _pushFunction(func) {
        let functionIndex = this.constructor.staticResourceToId.get(func) || 0;

        this._buffer.pushUint16(functionIndex);
    }

    _pushValue(value) {
        if (value === undefined) {
            this._buffer.pushUint8(UNDEFINED);
        } else if (value === null) {
            this._buffer.pushUint8(NULL);
        } else if (value === true) {
            this._buffer.pushUint8(TRUE);
        } else if (value === false) {
            this._buffer.pushUint8(FALSE);
        } else if (typeof value === 'number') {
            this._buffer.pushUint8(NUMBER);
            this._buffer.pushFloat32(value);
        } else if (typeof value === 'string') {
            this._buffer.pushUint8(STRING);
            this._pushString(value);
        } else if (value instanceof Map) {
            this._buffer.pushUint8(MAP);
            this._pushArray(Array.from(value));
        } else if (value instanceof Set) {
            this._buffer.pushUint8(SET);
            this._pushArray(Array.from(value));
        } else if (Array.isArray(value)) {
            this._buffer.pushUint8(ARRAY);
            this._pushArray(value);
        } else if (value instanceof ArrayBuffer || ArrayBuffer.isView(value)) {
            this._buffer.pushUint8(BUFFER);
            this._pushBuffer(value);
        } else if (value instanceof Promise) {
            this._buffer.pushUint8(UNDEFINED);
        } else if (typeof value === 'object') {
            if (value.constructor.serializable === false) {
                this._buffer.pushUint8(UNDEFINED);
            } else {
                this._buffer.pushUint8(OBJECT);
                this._pushObject(value);
            }
        } else if (typeof value === 'function') {
            this._buffer.pushUint8(FUNCTION);
            this._pushFunction(value);
        } else {
            this._buffer.pushUint8(UNDEFINED);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Serializer);