let DEFAULT_START_CAPACITY = 256;

export class FlexBuffer {
    constructor(buffer) {
        if (buffer instanceof ArrayBuffer) {
            this._offset = 0;
            this._buffer = buffer;
            this._size = buffer.byteLength;
            this._index = 0;
        } else if (ArrayBuffer.isView(buffer)) {
            this._offset = buffer.byteOffset;
            this._buffer = buffer.buffer;
            this._size = buffer.byteLength;
            this._index = 0;
        } else if (buffer instanceof FlexBuffer) {
            this._offset = buffer.byteOffset;
            this._buffer = buffer.buffer;
            this._size = buffer.byteLength;
            this._index = buffer._index;
        } else {
            this._offset = 0;
            this._buffer = new ArrayBuffer(DEFAULT_START_CAPACITY);
            this._size = 0;
            this._index = 0;
        }
        this._capacity = this._buffer.byteLength - this._offset;
        this._dataview = new DataView(this._buffer, this._offset);
    }

    get byteLength() {
        return this._size;
    }

    get buffer() {
        return this._buffer;
    }

    get byteOffset() {
        return this._offset;
    }

    arrayBuffer() {
        return this._buffer;
    }

    currentIndex() {
        return this._index;
    }
    
    size() {
        return this._size;
    }

    sliceBuffer() {
        return this._buffer.slice(this._offset, this._size);
    }

    reset() {
        this._size = 0;
        this._index = 0;
    }

    _extendBuffer() {
        // assumed that this will not be called when the FlexBuffer is initialized with an existing buffer
        let newBuffer = new ArrayBuffer(this._capacity * 2);
        let uint8Array = new Uint8Array(newBuffer);

        uint8Array.set(this._asUint8Array());

        this._buffer = newBuffer;
        this._dataview = new DataView(this._buffer);
        this._capacity = this._buffer.byteLength;
    }

    pushFloat32(value) {
        if (this._capacity < this._size + 4) {
            this._extendBuffer();
        }

        let currentSize = this._size;
        this._dataview.setFloat32(this._size, value);
        this._size += 4;

        return currentSize;
    }

    pushUint32(value) {
        if (this._capacity < this._size + 4) {
            this._extendBuffer();
        }

        let currentSize = this._size;
        this._dataview.setUint32(this._size, value);
        this._size += 4;

        return currentSize;
    }

    pushUint16(value) {
        if (this._capacity < this._size + 2) {
            this._extendBuffer();
        }

        let currentSize = this._size;
        this._dataview.setUint16(this._size, value);
        this._size += 2;

        return currentSize;
    }

    pushUint8(value) {
        if (this._capacity < this._size + 1) {
            this._extendBuffer();
        }

        let currentSize = this._size;
        this._dataview.setUint8(this._size, value);
        this._size += 1;

        return currentSize;
    }

    pushString(string) {
        this.pushUint16(string.length);

        for (let i = 0; i < string.length; ++i) {
            this.pushUint16(string.charCodeAt(i));
        }
    }

    readFloat32() {
        let value = this._dataview.getFloat32(this._index);
        this._index += 4;

        return value;
    }

    readUint32() {
        let value = this._dataview.getUint32(this._index);
        this._index += 4;

        return value;
    }

    readUint16() {
        let value = this._dataview.getUint16(this._index);
        this._index += 2;

        return value;
    }
    
    readUint8() {
        let value = this._dataview.getUint8(this._index);
        this._index += 1;

        return value;
    }

    readString() {
        let string = '';

        let length = this.readUint16();

        for (let i = 0; i < length; ++i) {
            string += String.fromCharCode(this.readUint16());
        }

        return string;
    }

    _asUint8Array() {
        return new Uint8Array(this._buffer, this._offset);
    }

    pushBuffer(buffer) {
        if (buffer instanceof FlexBuffer) {
            buffer = buffer._asUint8Array();
        } else if (buffer instanceof ArrayBuffer) {
            buffer = new Uint8Array(buffer);
        }

        while (this._capacity < this._size + buffer.byteLength) {
            this._extendBuffer();
        }

        let selfAsUint8Array = this._asUint8Array();

        selfAsUint8Array.set(buffer, this._size);
        this._size += buffer.byteLength;
    }
}

export function isFlexBufferCompatible(value) {
    return value instanceof ArrayBuffer || value instanceof FlexBuffer || ArrayBuffer.isView(value);
}
globalThis.ALL_FUNCTIONS.push(FlexBuffer);