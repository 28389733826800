import { CX, CY, ENERGY_CIRCLE_DURATION, PLANET_DIAMETER } from '../game-constants';

export class EnergyCircle {
    constructor(starSystem) {
        this.starSystem = starSystem;
        this.diameter = (starSystem.distanceBetweenRings * starSystem.rings.length + PLANET_DIAMETER) * 2;
        this.duration = ENERGY_CIRCLE_DURATION;
    }


    render(t) {
        let diameter = this.diameter * t;

        return {
            shape: 'circle',
            x: CX + this.starSystem.x,
            y: CY + this.starSystem.y,
            width: diameter,
            height: diameter,
            borderColor: this.starSystem.getStar().constructor.color,
            borderWidth: 5
        };
    }
}
globalThis.ALL_FUNCTIONS.push(EnergyCircle);