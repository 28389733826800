import { cloneObject } from '../utils/objects';
import { Graphics } from '../graphics';

export class GenericWidget extends Graphics {
    constructor({ box, text = '', formattedText = '', style = {}, disabledStyle = null, hoveredStyle = null, focusedStyle = null, disabled = false, focused = false }) {
        super();
        
        this.box = box;
        this.text = text;
        this.formattedText = formattedText;
        this.style = cloneObject(style);
        this.hoveredStyle = cloneObject(hoveredStyle);
        this.disabledStyle = cloneObject(disabledStyle);
        this.focusedStyle = cloneObject(focusedStyle);
        this._disabled = disabled;
        this.focused = focused;
    }

    get disabled() {
        return typeof this._disabled === 'function' ? this._disabled() : this._disabled;
    }

    set disabled(value) {
        this._disabled = value;
    }

    static HOVERED_STYLE = {
        overlayColor: 'white',
        overlayAlpha: 0.3,
        cursorSkin: 'pointer'
    }
    static DISABLED_STYLE = {
        overlayColor: 'black',
        overlayAlpha: 0.3
    }
    static FOCUSED_STYLE = {
        borderColor: 'blue'
    }

    isFocusable() {
        return !this.disabled;
    }

    hover() {
        if (this.disabled) {
            return null;
        }

        return this.hoveredStyle || this.constructor.HOVERED_STYLE;
    }

    processGraphics() {
        
    }

    render() {
        let graphics = {
            ...this.box,
            ...this.style,
            text: this.text,
            formattedText: this.formattedText,
        };

        this.processGraphics(graphics);

        if (this.disabled) {
            Object.assign(graphics, this.disabledStyle || this.constructor.DISABLED_STYLE || {});
        } else if (this.focused) {
            Object.assign(graphics, this.focusedStyle || this.constructor.FOCUSED_STYLE || {});
        }

        return graphics;
    }
}
globalThis.ALL_FUNCTIONS.push(GenericWidget);