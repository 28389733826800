import { isOneOf } from '../utils/array';
import { CHILDREN_LAYOUT_NAMES, Area } from './area';
import { Box } from '../utils/box';
import { isColor } from '../utils/colors';

export function layout(box, value) {
    let root = valueToArea(value);
    let result = {};
    let rootBox = Box.from(box);

    root.setBox(rootBox);

    for (let area of root.collect()) {
        if (area.id) {
            result[area.id] = area.box;
        }
    }

    return result;
}

function isLayoutType(string) {
    return isOneOf(string, CHILDREN_LAYOUT_NAMES);
}

function isCellShape(string) {
    return isOneOf(string, ['square', 'horizontal-hexagon', 'vertical-hexagon']);
}

function valueToArea(value) {
    let area = new Area();

    if (Array.isArray(value)) {
        area = valueListToAreaList(value)[0];
    } else if (typeof value === 'number') {
        area.force = value;
    } else {
        let fragments = value.split(' ').filter(x => x);

        for (let string of fragments) {
            let c = string[0];
            let substring = string.substring(1);

            if (isLayoutType(string)) {
                area.childrenLayout = string;
            // } else if (isCellShape(string)) {
            //     attributes.cellShape = string;
            } else if (c === '#') {
                area.id = substring;
            } else if (c === '%') {
                area.virtualWidth = parseFloat(substring);
                area.virtualHeight = 1;
            } else if (c === '*') {
                area.force = parseFloat(substring);
            } else if (c === ']') {
                area.margin = parseFloat(substring) / 100;
            } else if (/^\d+x\d+$/.test(string)) {
                let [ width, height ] = string.split('x').map(n => parseFloat(n));

                area.virtualWidth = width;
                area.virtualHeight = height;
            } else if (isColor(string)) {
                area.backgroundColor = string;
            }
        }
    }

    return area;
}

function valueListToAreaList(list) {
    let result = [];
    let lastArea = null;

    for (let value of list) {
        if (Array.isArray(value)) {
            lastArea.children.push(...valueListToAreaList(value));
        } else {
            lastArea = valueToArea(value);
            result.push(lastArea);
        }
    }

    return result;
}