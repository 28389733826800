export class AnimationWrapper {
    constructor({ animation, duration, delay }) {
        this.object = animation;
        this.duration = duration;
        this.delay = delay;
        this.startTime = 0;
        this.initialized = false;
        this.finished = false;
        this.resolve = null;
        this.promise = new Promise(resolve => this.resolve = resolve);
    }

    cancel() {
        this.finished = true;
    }
}

export function getAnimationDuration(animation) {
    return animation.duration ?? animation.constructor.duration ?? 0;
}

export function getAnimationDelay(animation) {
    return animation.delay ?? animation.constructor.delay ?? 0;
}

export function getAnimationTotalDuration(animation) {
    return getAnimationDuration(animation) + getAnimationDelay(animation);
}
globalThis.ALL_FUNCTIONS.push(AnimationWrapper);