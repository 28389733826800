import { REST_RATIO } from '../game-constants';
import { Star } from './star';

const ALLIED_POPULATION_GROWTH = 15;
const OPPONENT_POPULATION_GROWTH = 5;

export class Xeg extends Star {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'xeg'
    static color = 'black'
    static description = `+${ALLIED_POPULATION_GROWTH} population per turn to inner and outer planets. Planets controlled by your opponent only get +${OPPONENT_POPULATION_GROWTH}.`;

    getPlanetPopulationGrowth({ planet }) {
        if (this.isStarOf(planet)) {
            let ratio = planet.order.type === 'rest' ? REST_RATIO : 1;

            if (planet.owner === this.owner) {
                return ALLIED_POPULATION_GROWTH * ratio;
            } else {
                return OPPONENT_POPULATION_GROWTH * ratio;
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Xeg);