export const UNDEFINED = 0;
export const NULL = 1;
export const TRUE = 2;
export const FALSE = 3;
export const NUMBER = 4;
export const STRING = 5;
export const ARRAY = 6;
export const BUFFER = 7;
export const OBJECT = 9;
export const FUNCTION = 10;
export const SET = 11;
export const MAP = 12;