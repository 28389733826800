import { Lock } from '../lock';
import { Deserializer } from '../serialization/deserializer';
import { Serializer } from '../serialization/serializer';
import { EventTarget } from '../utils/event-target';
import { FlexBuffer, isFlexBufferCompatible } from '../utils/flex-buffer';

const CALL_CODE = 1;
const RESPONSE_CODE = 2;

export class Connection extends EventTarget {
    constructor({ webSocket, allowedMethods = [], name = null, expectAnswer = false, player = null, id = null, log = false }) {
        super();

        this._webSocket = webSocket;
        this._expectAnswer = expectAnswer;
        this._name = name;
        this._pendingCalls = new Map();
        this._nextCallId = 1;
        this._buffer = new FlexBuffer();
        this._connected = false;
        this._resolveReady = null;
        this._ready = new Promise(resolve => this._resolveReady = resolve);
        this._allowedMethods = new Set(allowedMethods);
        this._serializer = new Serializer();
        this._deserializer = new Deserializer();

        this.id = id;
        this.player = player;
        this.logEnabled = log;
        this.lock = new Lock();

        this.registerEvents(['connect', 'disconnect']);
        this.registerEvents(allowedMethods);

        this._webSocket.addEventListener('open', () => this._onConnect());
        this._webSocket.addEventListener('close', () => this._onDisconnect());
        this._webSocket.addEventListener('message', message => this._onMessage(message));

        if (this._webSocket.readyState === 1) {
            this._onConnect();
        }
    }

    static serializable = false

    _onConnect() {
        this._connected = true;
        this._resolveReady();

        this.triggerEvent('connect');
    }

    _onDisconnect() {
        this.triggerEvent('disconnect');
    }

    _log(message) {
        if (this.logEnabled) {
            console.log(message);
        }
    }

    _onMessage(message) {
        if (typeof Blob !== 'undefined' && message.data instanceof Blob) {
            message.data.arrayBuffer().then(buffer => this._onMessage({ data: new Uint8Array(buffer) }));
            return;
        }

        if (!ArrayBuffer.isView(message.data)) {
            this._log('_onMessage: not a buffer');
            return;
        }

        let buffer = new FlexBuffer(message.data);
        let code = buffer.readUint16();
        let callId = buffer.readUint32();
        let methodName = buffer.readString();
        let payload = this._deserializer.deserialize(buffer);

        if (code === CALL_CODE) {
            let result;

            if (!this._allowedMethods.has(methodName)) {
                result = { error: `method "${methodName}" does not exist` };
            } else {
                result = this.triggerEvent(methodName, payload).find(value => value !== undefined);
            }

            if (callId) {
                if (!(result instanceof Promise)) {
                    result = Promise.resolve(result);
                }

                result.then(value => {
                    this._send(RESPONSE_CODE, callId, methodName, value);
                });
            }
        } else if (code === RESPONSE_CODE) {
            let resolve = this._pendingCalls.get(callId);

            if (resolve) {
                // TODO: remove eventually
                if (payload && payload.error) {
                    this._log(`ERROR (${methodName}): ${payload.error}`);
                }

                this._pendingCalls.delete(callId);
                resolve(payload);
            }
        } else {
            this._log(`_onMessage: invalid code ${code}`);
        }
    }

    _callRemoteMethod(methodName, data) {
        let callId = this._nextCallId++;

        if (this._expectAnswer) {
            return new Promise(resolve => {
                this._pendingCalls.set(callId, resolve);
                this._send(CALL_CODE, callId, methodName, data);
            });
        } else {
            this._send(CALL_CODE, 0, methodName, data);
        }
    }

    _send(code, callId, methodName, data) {
        if (!isFlexBufferCompatible(data)) {
            data = this._serializer.serialize(data);
        }

        this._buffer.reset();
        this._buffer.pushUint16(code);
        this._buffer.pushUint32(callId);
        this._buffer.pushString(methodName);
        this._buffer.pushBuffer(data);

        let arrayBuffer = this._buffer.arrayBuffer().slice();

        this._ready.then(() => {
            this._webSocket.send(arrayBuffer);
        });
    }

    query(methodName, data) {
        return this._callRemoteMethod(methodName, data);
    }
}
globalThis.ALL_FUNCTIONS.push(Connection);