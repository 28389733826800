import { BASE_MOVE_RATIO } from '../game-constants';
import { Technology } from './technology';

const MOVE_RATIO = 0.90;

export class AllOut extends Technology {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'all-out';
    static color = 'orange';
    static description = `Move orders move ${MOVE_RATIO * 100}% of the planet's population instead of ${BASE_MOVE_RATIO * 100}%.`;

    getMoveRatio({ planet }) {
        if (planet.owner === this.owner) {
            return MOVE_RATIO;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(AllOut);