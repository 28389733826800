import { isSubClassOf } from '../engine/utils/objects';
import { Technology } from './technologies/technology';
import { formatId } from './utils';

export class SelectionCell {
    constructor({ box, parentArea, Content }) {
        this.box = box;
        this.parentArea = parentArea;
        this.Content = Content;
        this.selected = false;
    }

    getTooltip() {
        return this.Content.prototype.getTooltip();
    }

    render() {
        let scale = 0.9;

        if (isSubClassOf(this.Content, Technology)) {
            scale = 0.7;
        }
        
        return [
            {
                ...this.box,
                shape: 'rectangle',
                borderRadius: '5%',
                backgroundColor: this.selected ? 'black' : null,
                backgroundAlpha: 0.3,
            }, {
                ...this.box,
                image: `${this.Content.id}.png`,
                scale,
                formattedText: `|${formatId(this.Content.id)}`,
                textMaxWidth: '100%',
                textSize: '30%',
                textColor: 'black'
            }
        ];
    }
}
globalThis.ALL_FUNCTIONS.push(SelectionCell);