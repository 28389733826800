import { Planet } from './planet';

const STARTING_POPULATION = 10;

export class Berus extends Planet {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'berus';
    static color = 'black';
    static description = `Starts with +${STARTING_POPULATION} population. Allied move orders that go from or to this planet have +1 range.`;

    onGameStart() {
        this.population += STARTING_POPULATION;
    }

    getMoveBonusRange({ source, target }) {
        if (source === this && target.owner === this.owner) {
            return 1;
        }

        if (target === this && source.owner === this.owner) {
            return 1;
        }

        return 0;
    }
}
globalThis.ALL_FUNCTIONS.push(Berus);