export class SpaceRing {
    constructor({ starSystem, index, distanceFromCenter, bodyCount, startingAngle }) {
        this.starSystem = starSystem;
        this.index = index;
        this.distanceFromCenter = distanceFromCenter;
        this.startingAngle = startingAngle;
        this.bodies = new Array(bodyCount).fill(null);
    }

    set(index, body) {
        this.bodies[index] = body;
        body.indexOnRing = index;
        body.ring = this;
    }

    pushBody(Body, owner) {
        let index = this.bodies.findIndex(body => !body);

        if (index !== -1) {
            this.bodies[index] = new Body({
                owner,
                ring: this,
                indexOnRing: index
            });
        }
    }
}
globalThis.ALL_FUNCTIONS.push(SpaceRing);