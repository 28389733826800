export function removeItemFromArray(array, item) {
    let index = array.indexOf(item);

    if (index !== -1) {
        array.splice(index, 1);
    }
}

export function addItemToArray(array, item) {
    array.push(item);
}

export function getArrayItem(array, index) {
    return array[(index + array.length) % array.length];
}

export function getNextValue(array, value) {
    let index = array.findIndex(item => item === value);
    let nextIndex = (index + 1) % array.length;

    return array[nextIndex];
}

export function sum(array) {
    let sum = 0;

    for (let item of array) {
        sum += item;
    }

    return sum;
}

export function makeArray(length, value) {
    let array = new Array(length);

    array.fill(value);

    return array;
}

export function toArray(value) {
    if (Array.isArray(value)) {
        return value;
    } else if (value === undefined || value === null) {
        return [];
    } else {
        return [value];
    }
}

export function dedupe(array) {
    return Array.from(new Set(array));
}

export function isOneOf(value, array) {
    return array.includes(value);
}