export class PlanetsRotation {
    constructor(planets) {
        this.planets = planets;
    }

    static duration = 1;

    _rotatePlanets(t) {
        for (let planet of this.planets) {
            planet.indexOnRing = (Math.floor(planet.indexOnRing) + t) % planet.ring.bodies.length;
        }
    }

    render(t) {
        this._rotatePlanets(t);
    }

    onFinish() {
        this._rotatePlanets(1);
    }
}
globalThis.ALL_FUNCTIONS.push(PlanetsRotation);