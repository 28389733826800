import { computeAngle, computeDistance } from '../../engine/utils/math';
import { CONNECTION_DASH_LENGTH, CONNECTION_GAP_LENGTH, CONNECTION_WIDTH } from '../game-constants';

export class SpaceConnection {
    constructor({ source, target }) {
        this.source = source;
        this.target = target;
        this.visible = true;
    }

    isDetectable() {
        return false;
    }

    links(source, target) {
        return (this.source === source && this.target === target)
            || (this.source === target && this.target === source);
    }

    render(client) {
        if (!this.visible || client.game.animating) {
            return null;
        }

        let p1 = this.source.getPosition(client.data.mirror);
        let p2 = this.target.getPosition(client.data.mirror);

        return {
            shape: 'line',
            x: (p1.x + p2.x) / 2,
            y: (p1.y + p2.y) / 2,
            width: computeDistance(p1, p2),
            borderWidth: CONNECTION_WIDTH,
            borderColor: 'white',
            lineDashLength: CONNECTION_DASH_LENGTH,
            lineGapLength: CONNECTION_GAP_LENGTH,
            angle: computeAngle(p1, p2)
        };
    }
}
globalThis.ALL_FUNCTIONS.push(SpaceConnection);