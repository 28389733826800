import { Lock } from '../engine/lock';
import { QuadraticCurve } from '../engine/utils/curve';
import { isError } from '../engine/utils/error';
import { checkValueAgainstSchema, makeEnumSchema } from '../engine/utils/schema';
import { Announcement } from './animations/announcement';
import { EndGameAnnouncement } from './animations/end-game';
import { EnergyCircle } from './animations/energy-circle';
import { PlanetsRotation } from './animations/planets-rotation';
import { PopulationIncrease } from './animations/population-increase';
import { PopulationMove } from './animations/population-move';
import { CelestialBody } from './celestial-body';
import { ANNOUNCEMENT_DURATION, BASE_DEFENSE_STRENGTH, BASE_MOVE_RANGE, BASE_MOVE_RATIO, NORTH_STAR_SYSTEM_X, NORTH_STAR_SYSTEM_Y, REST_RATIO, SINGLE_PLAYER, SOUTH_STAR_SYSTEM_X, SOUTH_STAR_SYSTEM_Y, STAR_SYSTEM_DISTANCE_BETWEEN_RINGS, STAR_SYSTEM_RINGS, STAR_SYSTEM_X } from './game-constants';
import { Order } from './order';
import { PLANET_LIST } from './planets';
import { DefaultPlanet } from './planets/default';
import { Planet } from './planets/planet';
import { SpaceConnection } from './space/space-connection';
import { SpaceRing } from './space/space-ring';
import { StarSystem } from './space/star-system';
import { STAR_LIST } from './stars';
import { DefaultStar } from './stars/default';
import { NorthStar } from './stars/north';
import { Star } from './stars/star';
import { TECHNOLOGY_LIST } from './technologies';

const VALID_SET_SCHEMA = {
    starId: makeEnumSchema(STAR_LIST.map(item => item.id)),
    planetId: makeEnumSchema(PLANET_LIST.map(item => item.id)),
    technologyId: makeEnumSchema(TECHNOLOGY_LIST.map(item => item.id))
};

function instanciateById(classList, id, parameters) {
    let ClassObject = classList.find(c => c.id === id);

    if (ClassObject) {
        return new ClassObject(parameters);
    } else {
        return null;
    }
}

export class PrototypeGame {
    constructor(players) {
        // TODO: these fields should always be assigned like that, maybe force to inherit some base class?
        // other possibility: use a WeakMap to store these pieces of information
        this.players = players;
        this.lock = new Lock();

        this.winner = null;
        this.phase = 'planning';
        this.starSystems = [...players.map((player, i) => this._makePlayerStarSystem(player, i)), this._makeNorthNeutralStarSystem(2), this._makeSouthNeutralStarSystem(3)];
        this.technologies = players.map(player => this._makeTechnology(player));
        this.distances = new Map();
        this.planets = this.getAllPlanets();
        this.connections = [];
        this.entities = [
            ...this.technologies,
            ...this.starSystems.map(starSystem => starSystem.getStar()),
            ...this.starSystems.map(starSystem => starSystem.getAllPlanets()).flat(),
        ];

        this.animating = false;
        this.finishedTurns = new Set();

        this._onTurnStart();
    }

    _computeDistances() {
        this.distances.clear();

        let planets = this.getAllPlanets();
        let neighbors = new Map();

        for (let source of planets) {
            neighbors.set(source, planets.filter(target => this._arePlanetsConnected(source, target)));
        }

        // dijkstra - https://en.wikipedia.org/wiki/Dijkstra%27s_algorithm#Pseudocode
        for (let source of planets) {
            let dist = new Map();
            let Q = new Set();

            for (let planet of planets) {
                dist.set(planet, Infinity);
                Q.add(planet);
            }

            dist.set(source, 0);

            while (Q.size) {
                let u = [...Q].reduce((acc, loc) => dist.get(loc) < dist.get(acc) ? loc : acc);
                let neighborsInQ = neighbors.get(u).filter(v => Q.has(v));

                Q.delete(u);

                for (let v of neighborsInQ) {
                    let alt = dist.get(u) + 1;

                    if (alt < dist.get(v)) {
                        dist.set(v, alt);
                    }
                }
            }

            this.distances.set(source, dist);
        }
    }

    _computeConnections() {
        this.connections = [];

        for (let [source, distances] of this.distances.entries()) {
            for (let [target, distance] of distances) {
                if (distance === 1 && !this.connections.some(connection => connection.links(source, target))) {
                    this.connections.push(new SpaceConnection({ source, target }));
                }
            }
        }
    }

    _resetOrders() {
        for (let location of this.getAllPlanets()) {
            location.order = new Order({ source: location, target: null, type: 'rest' });
        }
    }

    _onTurnStart() {
        this.finishedTurns.clear();
        this._computeDistances();
        this._computeConnections();
        this._resetOrders();
        this.phase = 'planning';
    }

    _makeTechnology(player) {
        let { technologyId } = player.data.selectedSet;

        return instanciateById(TECHNOLOGY_LIST, technologyId, { owner: player });
    }

    _makePlayerStarSystem(player, index) {
        let { starId, planetId } = player.data.selectedSet;
        let starSystem = new StarSystem({
            index,
            owner: player,
            x: STAR_SYSTEM_X * (index === 0 ? -1 : 1),
            y: 80,
            rings: STAR_SYSTEM_RINGS,
            distanceBetweenRings: STAR_SYSTEM_DISTANCE_BETWEEN_RINGS,
            mirror: index === 1
        });

        const star = instanciateById(STAR_LIST, starId, { owner: player });
        const planet = instanciateById(PLANET_LIST, planetId, { owner: player });

        starSystem.setStar(star);
        starSystem.setPlanet(0, 0, planet);

        return starSystem;
    }

    _makeNorthNeutralStarSystem(index) {
        let starSystem = new StarSystem({
            index,
            owner: null,
            x: NORTH_STAR_SYSTEM_X,
            y: NORTH_STAR_SYSTEM_Y,
            rings: [2],
            distanceBetweenRings: 140
        });

        starSystem.setStar(new NorthStar());

        return starSystem;
    }

    _makeSouthNeutralStarSystem(index) {
        return new StarSystem({
            index,
            owner: null,
            x: SOUTH_STAR_SYSTEM_X,
            y: SOUTH_STAR_SYSTEM_Y,
            rings: [1],
            distanceBetweenRings: 0
        });
    }

    getDistance(source, target) {
        return this.distances.get(source)?.get(target) ?? Infinity;
    }

    _arePlanetsConnected(source, target) {
        let [s, t] = source.getId() < target.getId() ? [source, target] : [target, source];

        if (source === target) {
            return false
        } else if (source.ring === target.ring) {
            return Math.min(
                Math.abs(source.indexOnRing - target.indexOnRing),
                Math.abs(source.indexOnRing - target.indexOnRing - source.ring.bodies.length),
                Math.abs(source.indexOnRing - target.indexOnRing + source.ring.bodies.length)
            ) === 1;
        } else if (source.ring.starSystem === target.ring.starSystem) {
            let outerRingIndex = source.ring.index > target.ring.index ? source.indexOnRing : target.indexOnRing;
            let innerRingIndex = source.ring.index < target.ring.index ? source.indexOnRing : target.indexOnRing;

            return Math.abs(source.ring.index - target.ring.index) === 1
                && Math.floor(outerRingIndex / 2) === Math.min(innerRingIndex);
        } else if (s.ring.starSystem.index === 0 && t.ring.starSystem.index === 1) {
            return source.ring.index === source.ring.starSystem.rings.length - 1
                && target.ring.index === target.ring.starSystem.rings.length - 1
                && source.indexOnRing === target.indexOnRing
                && source.indexOnRing <= 1;
        } else if (s.ring.starSystem.index === 0 && t.ring.starSystem.index === 2) {
            return t.indexOnRing === 1 && s.ring.index === 1 && (s.indexOnRing === 5 || s.indexOnRing === 0);
        } else if (s.ring.starSystem.index === 1 && t.ring.starSystem.index === 2) {
            return t.indexOnRing === 0 && s.ring.index === 1 && (s.indexOnRing === 5 || s.indexOnRing === 0);
        } else if ((s.ring.starSystem.index === 0 || s.ring.starSystem.index === 1) && t.ring.starSystem.index === 3) {
            return s.ring.index === 1 && (s.indexOnRing === 1 || s.indexOnRing === 2);
        } else {
            return false;
        }
    }

    getAllPlanets() {
        return this.starSystems.map(starSystem => starSystem.getAllPlanets()).flat();
    }

    start() {
        for (let entity of this.entities) {
            entity.onGameStart();
        }

        for (let planet of this.planets) {
            planet.population += this._getPlanetPopulationGrowth(planet);
        }
    }

    _getPlanetPopulationGrowth(planet) {
        return this.entities.reduce((acc, entity) => acc + (entity.getPlanetPopulationGrowth({ planet }) || 0), 0);
    }

    _getWinner() {
        const p1Lost = !this.planets.find(planet => planet.constructor.id !== 'default-planet' && planet.owner === this.players[0]);
        const p2Lost = !this.planets.find(planet => planet.constructor.id !== 'default-planet' && planet.owner === this.players[1]);

        if (p1Lost && !p2Lost) {
            return this.players[1];
        } else if (!p1Lost && p2Lost) {
            return this.players[0];
        } else {
            return null;
        }
    }

    canPlanetMoveToDestination(source, target) {
        if (source === target) {
            return false;
        }

        let distance = this.getDistance(source, target);
        let maxAllowedDistance = this.entities.reduce((acc, entity) => acc + (entity.getMoveBonusRange({ source, target }) || 0), BASE_MOVE_RANGE);

        return distance <= maxAllowedDistance;
    }

    getMovedAmount(planet) {
        let ratio = this.entities.reduce((acc, entity) => entity.getMoveRatio({ planet }) || acc, BASE_MOVE_RATIO);

        return Math.ceil(planet.population * ratio);
    }

    getDefenseStrength(planet) {
        return this.entities.reduce((acc, entity) => entity.getDefenseStrengthMultiplier({ planet }) || acc, BASE_DEFENSE_STRENGTH);
    }

    update() {
        
    }

    async processPlayerInput(payload) {
        let methodName = `$${payload.input}`;

        if (!this[methodName]) {
            return { error: `input "${payload.input}" is not valid` };
        }

        return await this[methodName](payload);
    }

    async $endTurn({ player, api }) {
        this.finishedTurns.add(player);

        if (!SINGLE_PLAYER && this.finishedTurns.size !== 2) {
            return;
        }

        this.animating = true;
        this.phase = 'combat';

        await api.transition(new Announcement({ duration: ANNOUNCEMENT_DURATION, text: 'Combat phase', color: 'black' }));

        for (let planet of this.planets) {
            planet.tmpOwner = planet.owner;
        }

        for (let planet of this.planets) {
            for (let entity of this.entities) {
                entity.onCombatStart({ planet });
            }
        }

        let moves = [];
        let endTurnTransitions = [];
        
        for (let planet of this.planets) {
            let { source, target, type } = planet.order;

            if (type === 'move') {
                let movedCount = this.getMovedAmount(planet);

                for (let i = 0; i < movedCount; ++i) {
                    moves.push(new PopulationMove({ source, target, strength: 1 }));
                }
            } else if (type === 'defend') {
                let defenseStrength = this.getDefenseStrength(planet);

                planet.virtualDmgRatio = 1 - (1 / defenseStrength);
                planet.virtualPopulation = planet.population * (defenseStrength - 1);
            }
        }

        await api.transition(moves);

        for (let planet of this.planets) {
            planet.previousOwner = planet.owner;
            planet.owner = planet.tmpOwner;
            planet.tmpOwner = null;
            planet.virtualDmgRatio = 0;
            planet.virtualPopulation = 0;
            planet.population = Math.round(Math.abs(planet.population));
        }

        for (let planet of this.planets) {
            for (let entity of this.entities) {
                entity.onCombatEnd({ planet });
            }
        }

        for (let starSystem of this.starSystems) {
            if (starSystem.distanceBetweenRings > 0) {
                endTurnTransitions.push(new EnergyCircle(starSystem));

                for (let ring of starSystem.rings) {
                    for (let planet of ring.bodies) {
                        let growth = this._getPlanetPopulationGrowth(planet);

                        endTurnTransitions.push(new PopulationIncrease(planet, growth));
                    }
                }
            }
        }

        let winner = this._getWinner();

        if (winner) {
            await api.transition({ duration: 0.2 });
            this.winner = winner;
            return api.endGame({ winner });
        }

        await api.transition({ duration: 0.2 });
        this.phase = 'rotation';
        await api.transition(new PlanetsRotation(this.planets));
        // await api.transition({ duration: 0.3 });
        this.phase = 'growth';
        await api.transition(endTurnTransitions);
        this._onTurnStart();
        await api.transition(new Announcement({ duration: ANNOUNCEMENT_DURATION, text: 'Planning phase', color: 'black' }));
        this.animating = false;
    }

    async $setOrder({ player, data }) {
        let result = checkValueAgainstSchema(data, {
            source: obj => this.planets.includes(obj),
            target: obj => !obj || this.planets.includes(obj),
            type: ['enum', 'move', 'defend', 'rest']
        });

        if (isError(result)) {
            return result;
        }

        let { source, target, type } = data;

        if (!SINGLE_PLAYER && source.owner !== player) {
            return { error: `source does not belong to player` };
        }

        if (type === 'move') {
            if (!target) {
                return { error: `move target not specified` };
            }

            if (!this.canPlanetMoveToDestination(source, target)) {
                return { error: `source cannot move to the selected target` };
            }

            source.order.setType(type, target, this.getMovedAmount(source));
        } else {
            source.order.setType(type);
        }
    }

    static checkPlayer(player) {
        return checkValueAgainstSchema(player.data.selectedSet, VALID_SET_SCHEMA);
    }

    // TODO: try to generate this list automatically
    static functions = [
        StarSystem, SpaceRing, SpaceConnection, Order,
        CelestialBody, Planet, Star, DefaultStar, DefaultPlanet, NorthStar, ...PLANET_LIST, ...STAR_LIST, ...TECHNOLOGY_LIST,
        PlanetsRotation, QuadraticCurve
    ];
}
globalThis.ALL_FUNCTIONS.push(PrototypeGame);