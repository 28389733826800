export class SpatialIndex {
    constructor() {
        // TODO: use a real spatial index instead of a dumb array
        this._hitboxes = [];
    }

    register(object, box) {
        this._hitboxes.push({ object, box });
    }

    clear() {
        this._hitboxes = [];
    }

    hit(position, margin = 0) {
        let detectedObject = null;

        for (let { object, box } of this._hitboxes) {
            if (box.containsPoint(position, margin)) {
                detectedObject = object;
            }
        }

        return detectedObject;
    }
}
globalThis.ALL_FUNCTIONS.push(SpatialIndex);