import { capitalize } from '../engine/utils/string';

export function formatId(id) {
    return capitalize(id).replace(/-/g, ' ');
}

export function formatDescription(description) {
    if (Array.isArray(description)) {
        return description.join('\n');
    } else {
        return description;
    }
}