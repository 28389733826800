export class Graphics {
    constructor() {
    }

    isDetectable(state) {
        return true;
    }

    render(state) {
        return null;
    }
}
globalThis.ALL_FUNCTIONS.push(Graphics);