export const HEXAGON_WIDTH_TO_HEIGHT_RATIO = 0.8660254037844386;
export const HEXAGON_HEIGHT_TO_WIDTH_RATIO = 1 / HEXAGON_WIDTH_TO_HEIGHT_RATIO;
export const HEXAGON_NESTED_WIDTH_RATIO = 0.25;

export function isInvalidGridCell(cellShape, x, y, width, height) {
    if (cellShape === 'vertical-hexagon') {
        return (y % 2 === 0 && x === width - 1) || (height % 2 === 0 && y === height - 1);
    } else if (cellShape === 'hexagon' || cellShape === 'horizontal-hexagon') {
        return (x % 2 === 0 && y === height - 1) || (width % 2 === 0 && x === width - 1);
    }

    return false;
}

export function getPointOnQuadraticCurve(p1, p2, cp, t) {
    let u = 1 - t;
    let t2 = t * t;
    let u2 = u * u;
    let tu = 2 * t * u;
    let x = u2 * p1.x + tu * cp.x + t2 * p2.x;
    let y = u2 * p1.y + tu * cp.y + t2 * p2.y;

    return { x, y };
}

export function getQuadraticCurveControlPoint(p1, p2, m) {
    let dx = (p2.x - p1.x) * m;
    let dy = (p2.y - p1.y) * m;
    let cx = (p1.x + p2.x) / 2;
    let cy = (p1.y + p2.y) / 2;
    
    return {
        x: cx + dy,
        y: cy - dx
    };
}