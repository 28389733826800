import { STAR_DIAMETER } from '../game-constants';
import { Planet } from './planet';

export class DefaultPlanet extends Planet {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'default-planet';
    static color = 'white';

    getTooltip() {
        return null;
    }
}
globalThis.ALL_FUNCTIONS.push(DefaultPlanet);