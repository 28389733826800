import { CelestialBody } from '../celestial-body';
import { STAR_DIAMETER } from '../game-constants';
import { formatDescription, formatId } from '../utils';

export class Star extends CelestialBody {
    constructor(parameters) {
        super(parameters);
    }

    static diameter = STAR_DIAMETER;

    getTooltip(client) {
        if (!this.constructor.description) {
            return null;
        }

        let lines = [
            `#|${formatId(this.constructor.id)}#`,
            ``,
            formatDescription(this.constructor.description),
        ];

        if (client) {
            lines[0] += ` _(star)_`;
        }

        return lines.join('\n');
    }

    isStarOf(planet) {
        return this.ring.starSystem.includes(planet);
    }
}
globalThis.ALL_FUNCTIONS.push(Star);