import { CelestialBody } from '../celestial-body';
import { PLANET_DIAMETER } from '../game-constants';
import { formatDescription, formatId } from '../utils';

export class Planet extends CelestialBody {
    constructor(parameters) {
        super(parameters);

        this.previousOwner = this.owner;
        this.population = 0;
        this.tmpOwner = null;
        this.virtualDmgRatio = 0;
        this.virtualPopulation = 0;
        this.bonusPopulation = 0;
        this.order = null;
    }

    static diameter = PLANET_DIAMETER;

    getTooltip(client) {
        if (!this.constructor.description) {
            return null;
        }

        let lines = [
            `#|${formatId(this.constructor.id)}#`,
            ``,
            formatDescription(this.constructor.description)
        ];

        if (client) {
            lines.push(``);
            if (this.owner === client.player) {
                lines.push(`|_@small{(@blue{Allied headquarters}: you lose if your opponent takes control of this planet)}_`);
            } else {
                lines.push(`|_@small{(@red{Opponent headquarters}: you win if you take control of this planet)}_`);
            }
        }

        return lines.join('\n');
    }

    processGraphics(graphics) {
        graphics.text = Math.round(Math.abs(this.population + this.bonusPopulation)).toString();
    }
}
globalThis.ALL_FUNCTIONS.push(Planet);