import { Screen } from '../../engine/screen';
import { isInstanceOf } from '../../engine/utils/objects';
import { capitalize } from '../../engine/utils/string';
import { Button } from '../../engine/widgets/button';
import { EndGameAnnouncement } from '../animations/end-game';
import { CelestialBody } from '../celestial-body';
import { BATTLEFIELD_HEIGHT, BATTLEFIELD_WIDTH, CX, CY, SINGLE_PLAYER } from '../game-constants';
import { Order } from '../order';
import { Planet } from '../planets/planet';

export class GameScreen extends Screen {
    constructor(client) {
        super(client);

        this._playerNames = [];
        this._dummyOrder = new Order({ type: 'move' });

        this._background = {
            _static: true,
            backgroundColor: 'grey',
            x: CX,
            y: CY,
            width: BATTLEFIELD_WIDTH,
            height: BATTLEFIELD_HEIGHT
        };
        this._playerNames = this._game.players.map(player => ({
            _static: true,
            text: player.username,
            x: CX + 650 * (player === this._player ? -1 : 1),
            y: CY - 380,
            width: 200,
            height: 50,
            textSize: '95%',
            textColor: 'black',
            backgroundColor: 'white',
            borderColor: player === this._player ? 'blue' : 'red',
            borderWidth: '4%',
            borderRadius: '20%'
        }));
        this._endTurnButton = new Button({
            area: 'space',
            text: 'End turn',
            onClick: () => this._endTurn(),
            disabled: () => this._game.finishedTurns.has(this._player),
            style: {
                x: CX + 600,
                y: CY + 380,
                width: 300,
                height: 70,
                textSize: '65%',
                textColor: 'black',
                backgroundColor: 'white',
                borderColor: 'black',
                borderRadius: '20%',
                borderWidth: '5%'
            }
        });
        this._phaseLabel = {
            render: () => {
                return {
                    x: CX,
                    y: CY - 400,
                    width: 200,
                    height: 40,
                    text: capitalize(this._game.phase),
                    textSize: '100%',
                    textColor: 'white'
                }
            }
        };
        this._endGameAnnouncement = new EndGameAnnouncement();
    }

    get _game() {
        return this.client.player.game;
    }

    get _player() {
        return this.client.player;
    }

    _endTurn() {
        this.client.gameAction('endTurn');
    }

    _onNewGameState() {
        this.client.data.mirror = this._game.players[0] !== this._player;
    }

    _isPlanet(body) {
        return isInstanceOf(body, Planet);
    }

    _isOwnPlanet(body) {
        return this._isPlanet(body) && (SINGLE_PLAYER || body.owner === this._player);
    }

    _isValidMoveTarget(source, target) {
        return this._game.canPlanetMoveToDestination(source, target);
    }

    _getMovedAmount(planet) {
        return this._game.getMovedAmount(planet);
    }

    _sendOrder(source, target, type) {
        return this.client.gameAction('setOrder', { source, target, type });
    }

    init() {
        this._onNewGameState();

        this.client.on({
            playerStateChange() {
                this._onNewGameState();
            },
            keyDown(evt) {
                if (evt.code === 'Space') {
                    this.client.gameAction('endTurn');
                }
            }
        }, this);

        this.client.addMouseHandler({
            permanent: true,
            button: 'left',
            action: 'drag',
            isActive: () => !this._game.animating,
            target: {
                isValidObject: (location) => {
                    return this._isOwnPlanet(location);
                },
                hoverGraphics: {
                    cursorSkin: 'pointer',
                    overlayColor: 'black',
                    overlayAlpha: 0.3
                },
                onComplete: source => {
                    this._dummyOrder.source = source;
                    this._dummyOrder.target = source;
                    this._dummyOrder.movedAmount = this._getMovedAmount(source);
                    source.order.visible = false;

                    return {
                        button: 'left',
                        action: 'up',
                        grab: true,
                        targets: [
                            {
                                isValidObject: (location) => {
                                    return this._isPlanet(location) && this._isValidMoveTarget(source, location);
                                },
                                hoverGraphics: {
                                    cursorSkin: 'pointer',
                                    borderColor: 'lightgreen'
                                },
                                onComplete: (target) => {
                                    if (target.order.type === 'move' && target.owner === source.owner && target.order.target === source) {
                                        this._sendOrder(target, null, 'rest');
                                    }
                                    this._sendOrder(source, target, 'move').then(() => {
                                        this._dummyOrder.source = null;
                                        this._dummyOrder.target = null;
                                        source.order.visible = true;
                                        this.client.refresh();
                                    })
                                }
                            }, {
                                isValidObject: (location) => {
                                    return isInstanceOf(location, CelestialBody) && !this._isValidMoveTarget(source, location);
                                },
                                highlightGraphics: {
                                    overlayColor: 'black',
                                    overlayAlpha: 0.4
                                }
                            }
                        ],
                        onProgress: () => {
                            let { x, y, hovered } = this.client.getCursor();

                            if (this._isPlanet(hovered) && this._game.getDistance(source, hovered) <= 3) { // TODO
                                this._dummyOrder.target = hovered;
                            } else {
                                this._dummyOrder.target = { getPosition: () => ({ x, y }) };
                            }
                        },
                        onCancel: () => {
                            this._dummyOrder.source = null;
                            this._dummyOrder.target = null;
                            source.order.visible = true;
                        }
                    };
                }
            }
        });
        this.client.addMouseHandler({
            permanent: true,
            button: 'left',
            action: 'click',
            isActive: () => !this._game.animating,
            target: {
                isValidObject: (location) => {
                    return this._isOwnPlanet(location);
                },
                onComplete: (location) => {
                    this._sendOrder(location, null, 'rest');
                }
            }
        });
        this.client.addMouseHandler({
            permanent: true,
            button: 'right',
            action: 'click',
            isActive: () => !this._game.animating,
            target: {
                isValidObject: (location) => {
                    return this._isOwnPlanet(location);
                },
                onComplete: (location) => {
                    this._sendOrder(location, null, 'defend');
                }
            }
        });
        this.client.addMouseHandler({
            permanent: true,
            button: 'left',
            action: 'click',
            cursor: 'pointer',
            grab: true,
            isActive: () => this._game.winner,
            onFinish: () => {
                this.client.exitGame();
            }
        })
    }

    update() {
        let renderableObjects = [
            this._background,
            ...this._game.connections,
            ...this._game.getAllPlanets().map(planet => planet.order),
            this._dummyOrder,
            ...this._game.starSystems.map(starSystem => starSystem.getAllCelestialBodies()).flat(),
            ...this._playerNames,
            this._phaseLabel,
            ...this._game.technologies,
            this._endTurnButton,
            this._endGameAnnouncement
        ];

        this.client.render(renderableObjects);

        let { x, y, hovered, pressed } = this.client.getCursor();

        if (!pressed && hovered && hovered.getTooltip) {
            let tooltip = hovered.getTooltip(this.client);

            if (tooltip) {
                this.client.renderTooltip({ x, y }, tooltip);
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(GameScreen);