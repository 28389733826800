export const BATTLEFIELD_WIDTH = 1600;
export const BATTLEFIELD_HEIGHT = 900;
export const CX = BATTLEFIELD_WIDTH / 2;
export const CY = BATTLEFIELD_HEIGHT / 2;
export const STAR_SYSTEM_X = 350;
export const STAR_SYSTEM_Y = 0;
export const STAR_SYSTEM_DISTANCE_BETWEEN_RINGS = 140;
export const STAR_SYSTEM_RINGS = [3, 6];
export const NORTH_STAR_SYSTEM_X = 0;
export const NORTH_STAR_SYSTEM_Y = -280;
export const SOUTH_STAR_SYSTEM_X = 0;
export const SOUTH_STAR_SYSTEM_Y = 350;
export const STAR_DIAMETER = 80;
export const PLANET_DIAMETER = 60;
export const ORBIT_WIDTH = 1.5;
export const ARROW_WIDTH = 4;
export const ORDER_ICON_SIZE = 30;
export const INDICATOR_TEXT_SIZE = 25;
export const CONNECTION_WIDTH = 1;
export const CONNECTION_DASH_LENGTH = 10;
export const CONNECTION_GAP_LENGTH = 20;
export const TOTAL_PLANET_COUNT = STAR_SYSTEM_RINGS.reduce((a, b) => a + b);
export const BASE_MOVE_RATIO = 0.70;
export const BASE_DEFENSE_STRENGTH = 2;
export const ENERGY_CIRCLE_DURATION = 1;
export const REST_RATIO = 1.5;
export const ANNOUNCEMENT_DURATION = 1;
export const BASE_MOVE_RANGE = 1;

export const SINGLE_PLAYER = false;