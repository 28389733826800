import { computeAngle, computeDistance, getVector } from '../engine/utils/math';
import { BATTLEFIELD_HEIGHT, BATTLEFIELD_WIDTH, INDICATOR_TEXT_SIZE, BASE_MOVE_RATIO, NORTH_STAR_SYSTEM_X, NORTH_STAR_SYSTEM_Y, ORBIT_WIDTH, ORDER_ICON_SIZE, SOUTH_STAR_SYSTEM_X, SOUTH_STAR_SYSTEM_Y, STAR_SYSTEM_X, STAR_SYSTEM_Y, SINGLE_PLAYER } from './game-constants';

const TYPE_TO_ICON = {
    move: '➤',
    defend: '⛊',
    rest: '⛺'
};

const CX = BATTLEFIELD_WIDTH / 2;
const CY = BATTLEFIELD_HEIGHT / 2;
const POINTS_TO_AVOID = [
    { x: CX + STAR_SYSTEM_X, y: CY + STAR_SYSTEM_Y },
    { x: CX - STAR_SYSTEM_X, y: CY + STAR_SYSTEM_Y },
    { x: CX + NORTH_STAR_SYSTEM_X, y: CY + NORTH_STAR_SYSTEM_Y },
    { x: CX + SOUTH_STAR_SYSTEM_X, y: CY + SOUTH_STAR_SYSTEM_Y }
];

export class Order {
    constructor({ source, target, type } = {}) {
        this.source = source;
        this.target = target;
        this.type = type;
        this.movedAmount = 0;
        this.visible = true;
    }

    isDetectable() {
        return false;
    }

    setType(type, target = null, movedAmount = 0) {
        this.type = type;
        this.target = target;
        this.movedAmount = movedAmount;
    }

    render(client) {
        if (!this.source || !this.visible || (!SINGLE_PLAYER && this.source.owner !== client.player && !client.game.animating)) {
            return null;
        }

        let icon = TYPE_TO_ICON[this.type];
        let p1 = this.source.getPosition(client.data.mirror);
        let graphics = [];

        if (icon) {
            graphics.push({
                area: 'space',
                x: p1.x,
                y: p1.y - this.source.constructor.diameter / 2 - ORDER_ICON_SIZE / 2,
                width: ORDER_ICON_SIZE,
                height: ORDER_ICON_SIZE,
                text: icon,
                textSize: '100%',
                textColor: 'black'
            });
        }

        if (!client.game.animating && this.target && this.target !== this.source) {
            let p2 = this.target.getPosition(client.data.mirror);
            let w = computeDistance(p1, p2);
            let [cp1, cp2] = getIndicatorLocation(p1, p2);
            let cp = null;
            let mirror = false;

            // TODO: avoid planets as well?
            let d1 = Math.min(...POINTS_TO_AVOID.map(p => computeDistance(cp1, p)));
            let d2 = Math.min(...POINTS_TO_AVOID.map(p => computeDistance(cp2, p)));

            if (d1 >= d2) {
                cp = cp1;
                mirror = false;
            } else {
                cp = cp2;
                mirror = true;
            }

            graphics.push({
                area: 'space',
                shape: 'curve',
                x: (p1.x + p2.x) / 2,
                y: (p1.y + p2.y) / 2,
                width: w,
                height: w * 1/2,
                backgroundColor: 'black',
                angle: computeAngle(p1, p2),
                mirrorY: mirror
            });
            
            graphics.push({
                area: 'space',
                shape: 'rectangle',
                ...cp,
                width: INDICATOR_TEXT_SIZE * 2,
                height: INDICATOR_TEXT_SIZE,
                text: this.movedAmount,
                textColor: 'black',
                textSize: '90%',
                backgroundColor: 'white',
                borderRadius: '40%' 
            });
        }

        return graphics;
    }
}

function getIndicatorLocation(p1, p2) {
    let v = getVector(p1, p2);
    let len = Math.hypot(v.x, v.y);
    let m = (1 / len) * (len / 8);
    let px = v.y * m;
    let py = -v.x * m;
    let cx = (p1.x + p2.x) / 2;
    let cy = (p1.y + p2.y) / 2;

    return [
        { x: cx + px, y: cy + py },
        { x: cx - px, y: cy - py }
    ];
}
globalThis.ALL_FUNCTIONS.push(Order);