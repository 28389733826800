import { Box } from '../utils/box';
import { layoutItemsAsHorizontalLine, layoutItemsAsVerticalLine } from './line';

const CHILDREN_LAYOUT = {
    line: layoutItemsAsHorizontalLine,
    column: layoutItemsAsVerticalLine
};

export const CHILDREN_LAYOUT_NAMES = Object.keys(CHILDREN_LAYOUT);

export class Area {
    constructor() {
        this.id = null;
        this.margin = 0;
        this.childrenLayout = null;
        this.backgroundColor = null;
        this.virtualWidth = 0;
        this.virtualHeight = 1;
        this.force = 1;
        this.children = [];
        this.box = Box.null();
    }

    setBox(box) {
        this.box = box;

        if (!this.box) {
            return;
        }

        if (this.margin) {
            this.box = this.box.stripRatio(this.margin);
        }

        let aspectRatio = this.virtualWidth / this.virtualHeight;

        if (aspectRatio) {
            this.box = this.box.stripToMatchAspectRatio(aspectRatio);
        }

        let layoutChildren = CHILDREN_LAYOUT[this.childrenLayout] || defaultLayoutChildren;

        layoutChildren(this.box, this.children);
    }

    collect() {
        let result = [this];

        for (let child of this.children) {
            result.push(...child.collect());
        }

        return result;
    }
}

function defaultLayoutChildren(box, items) {
    for (let item of items) {
        item.setBox(null);
    }
}
globalThis.ALL_FUNCTIONS.push(Area);