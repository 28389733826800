export function getCurrentTime() {
    return Date.now() / 1000;
}

export function formatDuration(totalSeconds) {
    if (!isFinite(totalSeconds)) {
        return '∞';
    }

    totalSeconds = Math.ceil(totalSeconds);

    let seconds = totalSeconds % 60;
    let minutes = Math.floor(totalSeconds / 60);

    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}

export async function sleep(duration = 0) {
    return new Promise(resolve => setTimeout(resolve, duration));
}