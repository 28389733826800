import { sum } from '../utils/array';
import { Box } from '../utils/box';

function layoutItemsAsLine(isHorizontal, box, items) {
    let totalForce = sum(items.map(item => item.force || 1));
    let flexSpace = isHorizontal ? box.width : box.height;

    let x = box.x1;
    let y = box.y1;

    for (let i = 0; i < items.length; ++i) {
        let item = items[i];
        let force = item.force || 1;
        let boxWidth = box.width;
        let boxHeight = box.height;
        let boxX = x;
        let boxY = y;
        let variableDimension = force / totalForce * flexSpace;

        if (isHorizontal) {
            boxWidth = variableDimension;
            x += variableDimension;
        } else {
            boxHeight = variableDimension;
            y += variableDimension;
        }

        item.setBox(Box.fromTopLeftAndSize(boxX, boxY, boxWidth, boxHeight));
    }
}

export function layoutItemsAsHorizontalLine(box, items) {
    return layoutItemsAsLine(true, box, items);
}

export function layoutItemsAsVerticalLine(box, items) {
    return layoutItemsAsLine(false, box, items);
}