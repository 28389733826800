import { clamp } from '../utils/math';
import { GenericWidget } from './generic';

export class TextBox extends GenericWidget {
    constructor(parameters) {
        super(parameters);

        let {
            cursorIndex = 0,
            placeholder = '',
            placeholderColor = 'grey',
            isPassword = false,
            onEnter = () => {}
        } = parameters;

        this.cursorIndex = cursorIndex;
        this.isPassword = isPassword;
        this.placeholder = placeholder;
        this.placeholderColor = placeholderColor;
        this.onEnter = onEnter;
    }

    static HOVERED_STYLE = {
        cursorSkin: 'text'
    }

    onFocus(client) {
        if (this.disabled) {
            return;
        }

        this.focused = true;
        this.cursorIndex = this.text.length;
        client.addKeyHandler(this);
    }

    onUnfocus(client) {
        this.focused = false;
        client.removeKeyHandler(this);
    }

    onKeyDown(evt, client) {
        let intercepted = true;

        if (evt.key === 'Escape') {
            // client.click(null);
            return false;
        } else if (evt.key === 'Enter' && !evt.ctrlKey && !evt.shiftKey && !evt.altKey) {
            this.onEnter(client);
        } else {
            intercepted = this._editText(evt);
        }

        return intercepted;
    }

    processGraphics(graphics) {
        if (this.focused && !this.disabled) {
            graphics.textCursorIndex = this.cursorIndex;
        }

        if (!this.text) {
            graphics.text = this.placeholder;
            
            if (this.placeholderColor) {
                graphics.textColor = this.placeholderColor;
            }
        } else if (this.isPassword) {
            graphics.text = ''.padStart(this.text.length, '\u2022');
        }
    }

    _editText(evt) {
        let key = evt.key;
        let ctrl = evt.ctrlKey;
        let { text, cursorIndex } = this;
        let valid = true;

        cursorIndex = clamp(cursorIndex, 0, text.length);

        if (key === 'Backspace') {
            if (ctrl) {
                text = text.substring(cursorIndex);
                cursorIndex = 0;
            } else {
                text = text.substring(0, cursorIndex - 1) + text.substring(cursorIndex);
                cursorIndex -= 1;
            }
        } else if (key === 'Delete') {
            if (ctrl) {
                text = text.substring(0, cursorIndex);
            } else {
                text = text.substring(0, cursorIndex) + text.substring(cursorIndex + 1);
            }
        } else if (key === 'Home') {
            cursorIndex = 0;
        } else if (key === 'End') {
            cursorIndex = text.length;
        } else if (key === 'ArrowLeft') {
            if (ctrl) {
                cursorIndex = 0;
            } else {
                cursorIndex -= 1;
            }
        } else if (key === 'ArrowRight') {
            if (ctrl) {
                cursorIndex = text.length;
            } else {
                cursorIndex += 1;
            }
        } else if (isValidCharacter(key) && !ctrl) {
            text = text.substring(0, cursorIndex) + key + text.substring(cursorIndex, text.length);
            cursorIndex += 1;
        } else {
            valid = false;
        }

        if (valid) {
            cursorIndex = clamp(cursorIndex, 0, text.length);
            
            this.text = text;
            this.cursorIndex = cursorIndex;
        }

        return valid;
    }
}

function isValidCharacter(c) {
    return /^[0-9a-zA-Z_ ~!@#$%&*()+-=,<.>/?|]$/.test(c);
}
globalThis.ALL_FUNCTIONS.push(TextBox);