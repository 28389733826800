// https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript

export function hashNumber(number, x = 0) {
    x = ((x << 5) - x) + number;
    x = x & x;

    return x;
}

export function hashString(string, x = 0) {
    for (let i = 0; i < string.length; ++i) {
        let code = string.charCodeAt(i);

        x = hashNumber(code, x)
    }

    return x;
}

export function hashArray(array, x = 0) {
    for (let item of array) {
        x = hash(item, x);
    }

    return x;
}

export function hash(value, x = 0) {
    if (Array.isArray(value)) {
        return hashArray(value, x);
    } else if (typeof value === 'string') {
        return hashString(value, x);
    } else if (typeof value === 'number') {
        return hashNumber(value, x);
    } else {
        return x;
    }
}