import { Technology } from './technology';

export class ConqueringHands extends Technology {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'conquering-hands';
    static color = 'crimson';
    static description = `Move orders that target non-headquarters opponent planets have +1 range.`;

    getMoveBonusRange({ source, target }) {
        if (source.owner === this.owner && target.owner && target.owner !== this.owner && target.constructor.id === 'default-planet') {
            return 1;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(ConqueringHands);