export class FrameManager {
    constructor({ render, continuous = false }) {
        this._render = render;
        this._nextFrame = null;
        this._inFrame = false;
        this._renderNextFrame = false;
        this._continuous = continuous;

        if (this._continuous) {
            this.refresh();
        }
    }

    refresh() {
        if (this._inFrame) {
            this._renderNextFrame = true;
        } else if (!this._nextFrame) {
            this._scheduleNextFrame();
        }
    }

    _scheduleNextFrame() {
        this._nextFrame = window.requestAnimationFrame(() => this._triggerFrame());
    }

    _triggerFrame() {
        this._inFrame = true;
        this._render();
        this._inFrame = false;
        this._nextFrame = null;

        if (this._renderNextFrame || this._continuous) {
            this._renderNextFrame = false;
            this._scheduleNextFrame();
        }
    }
}
globalThis.ALL_FUNCTIONS.push(FrameManager);