import { Box } from './utils/box';
import { EventTarget } from './utils/event-target';

const BUTTON_TO_STRING = ['left', 'middle', 'right'];

export class WindowWrapper extends EventTarget {
    constructor({ virtualWidth, virtualHeight }) {
        super();

        this.registerEvents(['resize', 'keyDown', 'keyUp', 'mouseInput']);

        this._virtualWidth = virtualWidth;
        this._virtualHeight = virtualHeight;
        this._keys = {};
        this._buttons = {};
        this._x = 0;
        this._y = 0;
        this._canvas = null;
        this._canvasBox = Box.null();

        this._init();
    }

    get canvas() {
        return this._canvas;
    }

    _init() {
        let canvas = document.createElement('canvas');

        setTimeout(() => {
            document.body.style.margin = 0;
            document.body.style.backgroundColor = 'black';
            document.body.appendChild(canvas);
            this._onResize();
        });

        window.addEventListener('resize', () => this._onResize());
        canvas.addEventListener('mousemove', evt => this._onMouseMove(evt));
        canvas.addEventListener('mouseleave', evt => this._onMouseMove(evt));
        canvas.addEventListener('mousedown', evt => this._onMouseDown(evt));
        canvas.addEventListener('mouseup', evt => this._onMouseUp(evt));
        document.addEventListener('keydown', evt => this._onKeyDown(evt));
        document.addEventListener('keyup', evt => this._onKeyUp(evt));
        document.addEventListener('visibilitychange', () => this._resetKeys());
        document.addEventListener('contextmenu', evt => evt.preventDefault());

        this._canvas = canvas;
    }

    _emit(eventName, payload) {
        this.triggerEvent(eventName, payload);
    }

    _getXY(evt) {
        let { top, left } = this._canvas.getBoundingClientRect();
        let realToVirtualRatio = this._virtualWidth / this._canvas.width;
        let x = (evt.clientX - left) * realToVirtualRatio;
        let y = (evt.clientY - top) * realToVirtualRatio;

        return { x, y };
    }

    _triggerMouseEvent(payload) {
        this._x = payload.x;
        this._y = payload.y;

        if (payload.action === 'down') {
            this._buttons[payload.button] = true;
        } else if (payload.action === 'up') {
            this._buttons[payload.button] = false;
        }

        this._emit('mouseInput', payload);
    }

    _onMouseMove(evt) {
        this._triggerMouseEvent({
            action: 'move',
            ...this._getXY(evt),
            button: null,
            domEvent: evt
        });
    }

    _onMouseDown(evt) {
        this._triggerMouseEvent({
            action: 'down',
            ...this._getXY(evt),
            button: BUTTON_TO_STRING[evt.button],
            domEvent: evt
        });
    }

    _onMouseUp(evt) {
        this._triggerMouseEvent({
            action: 'up',
            ...this._getXY(evt),
            button: BUTTON_TO_STRING[evt.button],
            domEvent: evt
        });
    }

    _onKeyDown(evt) {
        this._keys[evt.code] = true;

        this.triggerEvent('keyDown', evt);
    }

    _onKeyUp(evt) {
        this._keys[evt.code] = false;

        this.triggerEvent('keyUp', evt);
    }

    _resetKeys() {
        for (let [code, pressed] of Object.entries(this._keys)) {
            if (pressed) {
                let evt = {
                    code,
                    key: '',
                    repeat: false,
                    altKey: false,
                    ctrlKey: false,
                    metaKey: false,
                    shiftKey: false,
                    domEvent: null
                };

                this._keys[code] = false;

                this.triggerEvent('keyUp', evt);
            }
        }
    }

    _onResize() {
        let requiredAspectRatio = this._virtualWidth / this._virtualHeight;
        let canvasBox = Box.fromTopLeftAndSize(0, 0, window.innerWidth, window.innerHeight)
            .stripToMatchAspectRatio(requiredAspectRatio)
            .round();
        
        this._canvas.width = canvasBox.width;
        this._canvas.height = canvasBox.height;
        this._canvas.style.position = 'absolute';
        this._canvas.style.left = `${canvasBox.x1}px`;
        this._canvas.style.top = `${canvasBox.y1}px`;
        this._canvasBox = canvasBox;

        this.triggerEvent('resize');
    }

    getCursorPosition() {
        return { x: this._x, y: this._y };
    }

    isMouseButtonPressed(button) {
        return !!this._buttons[button];
    }

    isKeyPressed(code) {
        return this._keys[code];
    }
}
globalThis.ALL_FUNCTIONS.push(WindowWrapper);