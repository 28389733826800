import { STAR_DIAMETER } from '../game-constants';
import { Star } from './star';

export class DefaultStar extends Star {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'default-star';
    static color = 'brown';
    static diameter = 0;
}
globalThis.ALL_FUNCTIONS.push(DefaultStar);