import { REST_RATIO } from '../game-constants';
import { Star } from './star';

const POPULATION_GROWTH = 20;

export class Zim extends Star {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'zim';
    static color = 'black';
    static description = `+${POPULATION_GROWTH} population per turn to inner and outer planets.`;

    getPlanetPopulationGrowth({ planet }) {
        if (this.isStarOf(planet)) {
            return POPULATION_GROWTH * (planet.order.type === 'rest' ? REST_RATIO : 1);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Zim);