export class Lock {
    constructor() {
        this.promise = Promise.resolve();
        this.resolve = null;
    }

    static serializable = false;

    grab() {
        let result = this.promise;

        this.promise = this.promise.then(() => {
            return new Promise(resolve => this.resolve = resolve);
        });

        return result;
    }

    release() {
        if (this.resolve) {
            this.resolve();
            this.resolve = null;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Lock);