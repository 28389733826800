export class Entity {
    constructor() {

    }

    onGameStart() {

    }

    onCombatStart({ planet }) {

    }

    onCombatEnd({ planet, previousOwner }) {

    }

    getPlanetPopulationGrowth({ planet }) {

    }

    getMoveBonusRange({ source, target }) {

    }

    getMoveRatio({ planet }) {
        
    }

    getDefenseStrengthMultiplier({ planet }) {

    }
}
globalThis.ALL_FUNCTIONS.push(Entity);