import { Atov } from './atov';
import { Berus } from './berus';
import { Crypso } from './crypso';
import { Dorax } from './dorax';
import { Etys } from './etys';
import { Farvis } from './farvis';
import { Gnora } from './gnora';
import { Hitania } from './hitania';
import { Izerth } from './izerth';
import { Jocar } from './jocar';
import { Koth } from './koth';

// export const PLANET_LIST = [Atov, Berus, Crypso, Dorax, Etys, Farvis, Gnora, Hitania, Izerth, Jocar, Koth];
export const PLANET_LIST = [Atov, Berus, Crypso];

let COLORS = ['aquamarine', 'blueviolet', 'coral', 'darkseagreen', 'deepskyblue', 'firebrick', 'goldenrod', 'hotpink', 'dodgerblue', 'burlywood', 'lightblue'];
PLANET_LIST.forEach((planet, i) => planet.color = COLORS[i]);