import { Technology } from './technology';

const POPULATION_BOOST = 40;

export class Reconstruction extends Technology {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'reconstruction';
    static color = 'olive';
    static description = `When you take control of an opponent planet, it gets +${POPULATION_BOOST} population.`;

    onCombatEnd({ planet }) {
        if (planet.owner === this.owner && planet.previousOwner && planet.previousOwner !== this.owner) {
            planet.population += POPULATION_BOOST;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Reconstruction);