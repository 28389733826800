import { Technology } from './technology';

const POPULATION_BOOST = 10;

export class EmergencyBreeding extends Technology {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'emergency-breeding';
    static color = 'hotpink';
    static description = `Defense orders also grant +${POPULATION_BOOST} population.`;

    onCombatStart({ planet }) {
        if (planet.owner === this.owner && planet.order.type === 'defend') {
            planet.population += POPULATION_BOOST;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(EmergencyBreeding);