export class Screen {
    constructor(client) {
        this.client = client;
    }

    get player() {
        return this.client.player;
    }

    init() {
        
    }

    update() {

    }

    destroy() {
        
    }

    refresh() {
        this.client.refresh();
    }
}
globalThis.ALL_FUNCTIONS.push(Screen);