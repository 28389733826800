export function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
}

export function round(value) {
    return Math.round(value);
}

export function floor(value) {
    return Math.floor(value);
}

export function ceil(value) {
    return Math.ceil(value);
}

export function inRange(value, min, max) {
    return value >= min && value <= max;
}

export function computeAngle(p1, p2) {
    let dx = p2.x - p1.x;
    let dy = p2.y - p1.y;
    
    return Math.atan2(dy, dx);
}

export function computeDistance(p1, p2) {
    let dx = p2.x - p1.x;
    let dy = p2.y - p1.y;

    return Math.hypot(dx, dy);
}

export function getVector(p1, p2) {
    return {
        x: p2.x - p1.x,
        y: p2.y - p1.y
    };
}

export function computeNormalizedVector(p1, p2) {
    let dx = p2.x - p1.x;
    let dy = p2.y - p1.y;
    let len = Math.hypot(dx, dy);

    return {
        x: dx / len,
        y: dy / len
    };
}

export function getRandomFloat(min, max) {
    return Math.random() * (max - min) + min;
}