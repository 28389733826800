import { SpaceRing } from './space-ring';
import { DefaultPlanet } from '../planets/default';
import { DefaultStar } from '../stars/default';

export class StarSystem {
    constructor({ index, owner, x, y, rings, distanceBetweenRings, startingAngle = 0, mirror = false }) {
        this.index = index;
        this.owner = owner;
        this.x = x;
        this.y = y;
        this.mirror = mirror;
        this.distanceBetweenRings = distanceBetweenRings;
        this.rings = rings.map((bodyCount, i) => new SpaceRing({
            starSystem: this,
            index: i,
            distanceFromCenter: distanceBetweenRings * (i + 1),
            startingAngle: (-1 / (bodyCount * 2)) * (i % 2) + startingAngle,
            bodyCount
        }));
        this.starRing = new SpaceRing({
            starSystem: this,
            index: 0,
            distanceFromCenter: 0,
            startingAngle: 0,
            bodyCount: 1
        });

        this.starRing.pushBody(DefaultStar, owner);

        for (let ring of this.rings) {
            for (let i = 0; i < ring.bodies.length; ++i) {
                ring.pushBody(DefaultPlanet, owner);
            }
        }
    }

    get ringCount() {
        return this.rings.length;
    }

    getStar() {
        return this.starRing.bodies[0];
    }

    setStar(star) {
        this.starRing.set(0, star);
    }

    getPlanet(ringIndex, indexOnRing) {
        return this.rings[ringIndex].bodies[indexOnRing];
    }

    setPlanet(ringIndex, indexOnRing, planet) {
        this.rings[ringIndex].set(indexOnRing, planet);
    }

    getAllPlanets() {
        return this.rings.map(ring => ring.bodies).flat();
    }

    getAllCelestialBodies() {
        return [this.getStar(), ...this.getAllPlanets()];
    }

    includes(planet) {
        for (let ring of this.rings) {
            for (let body of ring.bodies) {
                if (body === planet) {
                    return true;
                }
            }
        }

        return false;
    }
}
globalThis.ALL_FUNCTIONS.push(StarSystem);