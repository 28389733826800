import { TAU } from '../engine/utils/constants';
import { Entity } from './entity';
import { CX, CY, ORBIT_WIDTH } from './game-constants';

export class CelestialBody extends Entity {
    constructor({ owner = null, ring, indexOnRing } = {}) {
        super();
        
        this.owner = owner;
        this.ring = ring;
        this.indexOnRing = indexOnRing;
    }

    static description = null;

    getId() {
        return (this.ring.starSystem.index << 8)
            + (this.ring.index << 4)
            + this.indexOnRing;
    }

    getPosition(mirror) {
        let starSystem = this.ring.starSystem;
        let ringBodyCount = this.ring.bodies.length;
        let distanceFromCenter = this.ring.distanceFromCenter;
        let ringStartingAngle = this.ring.startingAngle;
        let angle = (this.indexOnRing / ringBodyCount + ringStartingAngle) * TAU;
        let x = starSystem.x + Math.cos(angle) * distanceFromCenter * (starSystem.mirror ? -1 : 1);
        let y = starSystem.y + Math.sin(angle) * distanceFromCenter;

        if (mirror) {
            x *= -1;
        }

        return { x: CX + x, y: CY + y };
    }

    render(client) {
        let graphics = {
            ...this.getPosition(client.data.mirror),
            width: this.constructor.diameter,
            height: this.constructor.diameter,
            shape: 'circle',
            backgroundColor: this.constructor.color || '#eeeeee',
            borderColor: 'black',
            borderWidth: ORBIT_WIDTH * 2,
            textHorizontalAlign: 'center',
            textSize: '35%',
            text: null
        };

        let owner = this.tmpOwner || this.owner;

        if (owner) {
            if (owner === client.player) {
                graphics.borderColor = 'blue';
            } else {
                graphics.borderColor = 'red';
            }
        }

        this.processGraphics(graphics);

        return graphics;
    }

    processGraphics(graphics) {

    }
}
globalThis.ALL_FUNCTIONS.push(CelestialBody);