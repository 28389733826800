import { FrameManager } from './frame-manager';
import { WindowWrapper } from './window-wrapper';
import { Renderer } from './renderer/renderer';
import { EventTarget } from './utils/event-target';
import { Screen } from './screen';
import { SpatialIndex } from './spatial-index';
import { AnimationWrapper, getAnimationDelay, getAnimationDuration } from './animation-wrapper';
import { getCurrentTime } from './utils/time';
import { toArray } from './utils/array';
import { evaluate } from './utils/functions';
import { onClientOrServerStart } from './misc';
import { Connection } from './network/connection';
import { ClientGameApi } from './client-game-api';
import { replaceAddressesWithValues, replaceValuesWithAddresses } from './utils/cross-network-ref';
import { isError } from './utils/error';

export const CLIENT_API = [ 'notifyPlayerStateChange', 'notifyPlayerGameInput' ];

export class Client extends EventTarget {
    constructor(gameDefinition, webSocketServerUrl) {
        super();

        let {
            Game,
            client: {
                virtualWidth = 1600,
                virtualHeight = 900,
                backgroundColor = 'black',
                autoLogin = false,
                autoSwitchScreen = false,
                screens = {},
                onNewPlayerState = () => {},
                onStart = () => {}
            }
        } = gameDefinition;
        let webSocket = new WebSocket(webSocketServerUrl);

        this._window = new WindowWrapper({ virtualWidth, virtualHeight });
        this._frameManager = new FrameManager({ render: () => this._update(), continuous: false });
        this._renderer = new Renderer({ canvas: this._window.canvas, backgroundColor, virtualWidth, virtualHeight });
        this._spatialIndex = new SpatialIndex();
        this._server = new Connection({ webSocket, allowedMethods: CLIENT_API, expectAnswer: true, log: true });
        this._screen = null;
        this._screenId = null;
        this._screenReady = false;
        this._keyHandlers = new Set();
        this._mouseHandlers = new Set();
        this._animations = [];
        this._afterAnimationsResolves = [];
        this._player = {};
        this._focusedObject = null;
        this._objectUnderCursor = null;
        this._pressedObject = null;
        this._screens = screens;
        this._userOnNewPlayerState = onNewPlayerState;
        this._userOnStart = onStart;
        this._birthTime = getCurrentTime();
        this._currentTime = 0;
        this._gameApi = new ClientGameApi(this);
        this._updateChain = Promise.resolve();
        this._autoLogin = autoLogin;
        this._autoSwitchScreen = autoSwitchScreen;
        this._data = {};

        onClientOrServerStart(Game);

        this._init();
    }

    get player() {
        return this._player;
    }

    get game() {
        return this._player.game;
    }

    get data() {
        return this._data;
    }

    _init() {
        this._window.on({
            resize() {
                this._renderer.clearCache();
                this._refresh();
            },
            mouseInput(evt) {
                this._onMouseInput(evt);
            },
            keyDown(evt) {
                this._onKeyDown(evt);
            }
        }, this);

        this._server.on({
            notifyPlayerStateChange(data) {
                this._onNewPlayerState(data);
            },
            notifyPlayerGameInput(data) {
                this._onPlayerInput(data);
            }
        }, this);

        this.registerEvents(['keyDown', 'playerStateChange']);
        this._userOnStart(this);
        this.loadScreen(Object.keys(this._screens)[0]);
    }

    _onNewPlayerState({ player, source }) {
        delete player.connection; // TODO: do that more cleanly in the serialization process
        console.log(source);
        console.log(player);

        this._updateChain = this._updateChain.then(() => {
            this._player = player;
            this._changeScreenIfNecessary(source);
            this._userOnNewPlayerState(this, player);
            this.triggerEvent('playerStateChange');
        });

        this._refresh();
    }

    _changeScreenIfNecessary() {
        let { id, game } = this._player;
        let currentScreen = this._screenId;

        if (!id && currentScreen !== 'welcome') {
            if (this._autoSwitchScreen) {
                this.loadScreen('welcome');
            }
        } else if (id && !game && currentScreen !== 'lobby') {
            if (this._autoSwitchScreen) {
                this.loadScreen('lobby');
            }
        } else if (id && game && currentScreen !== 'game') {
            if (this._autoSwitchScreen) {
                this.loadScreen('game');
            }
        }
    }

    _onPlayerInput({ playerId, input, data }) {
        this._updateChain = this._updateChain.then(() => {
            let game = this.player.game;
            let player = game?.players.find(player => player.id === playerId);
            let api = this._gameApi;

            if (game) {
                data = replaceAddressesWithValues(data, game);
                this._refresh();
                return game.processPlayerInput({ player, input, data, api });
            }

            // TODO: maybe also update with the server-side state
        });
    }

    _refresh() {
        this._frameManager.refresh();
    }

    _update() {
        if (!this._screenReady) {
            return;
        }

        this._currentTime = getCurrentTime() - this._birthTime;
        this._objectUnderCursor = this._spatialIndex.hit(this._window.getCursorPosition());
        this._renderer.clear();
        this._spatialIndex.clear();

        this._screen.update();
        this._renderAnimations();
    }

    _renderAnimations() {
        let skipAnimations = !this._animations.length;

        if (skipAnimations) {
            return;
        }

        for (let animation of this._animations) {
            if (!animation.initialized) {
                animation.initialized = true;
                animation.startTime = this._currentTime + animation.delay;
                if (animation.object.onStart) {
                    animation.object.onStart();
                }
            }

            // TODO: maybe add easing?

            let elapsedSinceStart = this._currentTime - animation.startTime;
            let t = elapsedSinceStart / animation.duration;

            if (t >= 1) {
                this._terminateAnimation(animation);
            } else if (t >= 0 && animation.object.render) {
                let graphics = animation.object.render(t, this);

                this._renderer.render(graphics);
            }
        }

        this._animations = this._animations.filter(animation => !animation.finished);
        this._refresh();

        if (!this._animations.length) {
            for (let resolve of this._afterAnimationsResolves) {
                resolve();
            }
        }
    }

    _terminateAnimation(animation) {
        if (!animation.initialized && animation.object.onStart) {
            animation.object.onStart();
        }

        animation.finished = true;
        if (animation.object.onFinish) {
            animation.object.onFinish();
        }
        animation.resolve();
    }

    _onMouseInput(evt) {
        let action = evt.action;
        let button = evt.button;

        if (action === 'down') {
            this._pressedObject = this._objectUnderCursor;
        } else if (action === 'up') {
            if (this._objectUnderCursor === this._pressedObject) {
                action = 'click';
            }
            this._pressedObject = null;
        } else if (action === 'move' && this._pressedObject) {
            action = 'drag';
        }

        let target = this._objectUnderCursor;

        if (action === 'drag') {
            button = 'left';
            target = this._pressedObject;
        }

        let handlers = Array.from(this._mouseHandlers).reverse().filter(handler => !handler.isActive || handler.isActive(this));

        for (let handler of handlers) {
            if ((handler.action === action || (action === 'click' && handler.action === 'up')) && handler.button === button) {
                let validTargetFound = false;
                let nextHandler = null;

                if (target) {
                    for (let handlerTarget of getHandlerTargets(handler)) {
                        if (handlerTarget.onComplete && handlerTarget.isValidObject(target)) {
                            validTargetFound = true;
                            nextHandler = handlerTarget.onComplete(target, this);
                            break;
                        }
                    }
                }

                if (!validTargetFound && handler.onCancel) {
                    nextHandler = handler.onCancel(target, this) || nextHandler;
                }

                if (handler.onFinish) {
                    nextHandler = handler.onFinish(target, this) || nextHandler;
                }

                if (nextHandler) {
                    this.addMouseHandler(nextHandler);
                }

                if (!handler.permanent) {
                    this._mouseHandlers.delete(handler);
                }

                if (validTargetFound && action === 'drag') {
                    this._pressedObject = null;
                }

                if (handler.grab) {
                    break;
                }
            } else if (handler.onProgress) {
                handler.onProgress();
            }
        }

        this._refresh();
    }

    _onKeyDown(evt) {
        let intercepted = false;

        for (let sink of this._keyHandlers) {
            intercepted = sink.onKeyDown(evt, this);

            if (intercepted) {
                break;
            }
        }

        if (!intercepted) {
            this.triggerEvent('keyDown', evt);
        }

        this._refresh();
    }

    _isLeftClickable(object) {
        return object.onClick && (!object.isClickable || object.isClickable(this));
    }

    _isFocusable(object) {
        return object.onFocus && (!object.isFocusable || object.isFocusable(this));
    }

    _resetHandlers() {
        this._keyHandlers.clear();
        this._mouseHandlers.clear();

        // TODO: factorize these
        this.addMouseHandler({
            permanent: true,
            button: 'right',
            action: 'click',
            target: {
                isValidObject: object => object.onRightClick && (!object.isClickable || object.isClickable(this)),
                hoverGraphics: object => object.hover && object.hover(this),
                onComplete: object => object.onRightClick(this)
            }
        });
        this.addMouseHandler({
            permanent: true,
            button: 'left',
            action: 'up',
            target: {
                isValidObject: object => object.onMouseUp && (!object.isClickable || object.isClickable(this)),
                hoverGraphics: object => object.hover && object.hover(this),
                onComplete: object => object.onMouseUp(this)
            }
        });
        this.addMouseHandler({
            permanent: true,
            button: 'left',
            action: 'down',
            target: {
                isValidObject: object => object.onMouseDown && (!object.isClickable || object.isClickable(this)),
                hoverGraphics: object => object.hover && object.hover(this),
                onComplete: object => object.onMouseDown(this)
            }
        });
        this.addMouseHandler({
            permanent: true,
            button: 'left',
            action: 'click',
            target: {
                isValidObject: object => this._isLeftClickable(object) || this._isFocusable(object),
                hoverGraphics: object => object.hover && object.hover(this),
                onComplete: object => {
                    this.focus(object);
                    if (this._isLeftClickable(object)) {
                        return object.onClick(this);
                    }
                }
            },
            onCancel: () => {
                this.focus(null);
            }
        });
        this.addMouseHandler({
            permanent: true,
            button: 'left',
            action: 'drag',
            target: {
                isValidObject: object => object.onDragStart && (!object.isDraggable || object.isDraggable(this)),
                hoverGraphics: object => object.hover && object.hover(this),
                onComplete: object => object.onDragStart(this)
            }
        });
    }

    async start() {
        let username = this.getLocalStorageItem('username');

        if (this._autoLogin && username) {
            return await this.authenticate({ username });
        }
    }

    getCursor() {
        let { x, y } = this._window.getCursorPosition();
        let hovered = this._objectUnderCursor;
        let pressed = this._window.isMouseButtonPressed('left') || this._window.isMouseButtonPressed('right');

        return { x, y, hovered, pressed };
    }

    loadScreen(id, data) {
        if (this._screen) {
            this._screen.destroy();
            this.removeEventCallbacks(this._screen);
        }

        let ScreenClass = this._screens[id] || Screen;

        this._renderer.clearCache();
        this._objectUnderCursor = null;
        this._pressedObject = null;
        this._focusedObject = null;
        this._resetHandlers();
        this._screenId = id;
        this._screen = new ScreenClass(this, data);
        this._screenReady = false;

        Promise.resolve()
            .then(() => this._screen.init())
            .then(() => {
                this._screenReady = true;
                this._refresh();
            });
    }

    getCurrentScreenId() {
        return this._screenId;
    }

    render(objects) {
        let handlers = Array.from(this._mouseHandlers).reverse().filter(handler => !handler.isActive || handler.isActive(this));

        let cursor = null;
        let cursorImage = null;
        let tooltip = null;

        for (let handler of handlers) {
            if (handler.cursor) {
                cursor = handler.cursor;
            }

            if (handler.cursorImage) {
                cursorImage = handler.cursorImage;
            }

            if (handler.grab) {
                break;
            }
        }

        for (let object of objects) {
            let isUnderCursor = this._objectUnderCursor === object;
            let isDetectable = !object._static && (!object.isDetectable || object.isDetectable(this));
            let highlightGraphics = {};

            if (isDetectable) {
                for (let handler of handlers) {
                    for (let target of getHandlerTargets(handler)) {
                        if (target.isValidObject(object)) {
                            Object.assign(highlightGraphics, evaluate(target.highlightGraphics, object) || {});

                            if (isUnderCursor) {
                                Object.assign(highlightGraphics, evaluate(target.hoverGraphics, object) || {});
                            }

                            break;
                        }
                    }

                    if (handler.grab) {
                        break;
                    }
                }
            }

            let grahpicsList = toArray(object._static ? object : (object.render && object.render(this)));
            let box = this._renderer.render(grahpicsList[0], highlightGraphics);

            for (let i = 1; i < grahpicsList.length; ++i) {
                this._renderer.render(grahpicsList[i]);
            }

            if (isUnderCursor) {
                let g = grahpicsList[0];

                if (g.cursorSkin) {
                    cursor = g.cursorSkin;
                }

                if (g.tooltip) {
                    tooltip = g.tooltip;
                }
            }

            if (isDetectable) {
                this._spatialIndex.register(object, box);
            }
        }

        if (cursorImage) {
            this._renderer.renderImageOnCursor(this._window.getCursorPosition(), cursorImage, 0.05);
        }

        if (cursor) {
            this._renderer.setCursor(cursor);
        }
    }

    addKeyHandler(handler) {
        this._keyHandlers.add(handler);
    }

    removeKeyHandler(handler) {
        this._keyHandlers.delete(handler);
    }

    addMouseHandler(handler) {
        this._mouseHandlers.add(handler);
    }

    removeMouseHandler(handler) {
        this._mouseHandlers.delete(handler);
    }

    click(object) {
        // TODO
    }

    focus(object = null) {
        if (this._focusedObject === object) {
            return;
        }

        if (this._focusedObject && this._focusedObject.onUnfocus) {
            this._focusedObject.onUnfocus(this);
        }

        this._focusedObject = object;

        if (this._focusedObject && this._focusedObject.onFocus) {
            this._focusedObject.onFocus(this);
        }
    }

    changeFocus(objects, direction) {
        let objectsThatCanBeFocused = objects.filter(object => this._isObjectFocusable(object));
        let modifier = Math.sign(direction);
        let currentIndex = objectsThatCanBeFocused.indexOf(this._focusedObject);
        let indexToFocus = 0;

        if (currentIndex === -1 && modifier === -1) {
            indexToFocus = objectsThatCanBeFocused.length - 1;
        } else {
            indexToFocus = (objectsThatCanBeFocused.length + currentIndex + modifier) % objectsThatCanBeFocused.length;
        }

        this.focus(objectsThatCanBeFocused[indexToFocus]);
    }

    authenticate({ username, password }) {
        return this._server.query('authenticate', { username }).then(result => {
            if (!isError(result)) {
                this.setLocalStorageItem('username', username);
            }

            return result;
        })
    }

    logout() {
        return this._server.query('logout', null).then(result => {
            if (!isError(result)) {
                this.removeLocalStorageItem('username');
            }

            return result;
        });
    }

    savePlayerData() {
        return this._server.query('setOwnData', this._player.data);
    }

    startMatchmaking() {
        return this._server.query('startMatchmaking', null);
    }

    stopMatchmaking() {
        return this._server.query('stopMatchmaking', null);
    }

    gameAction(name, data) {
        data = replaceValuesWithAddresses(data, this._player.game);
        return this._server.query('gameAction', { name, data });
    }

    exitGame() {
        return this._server.query('exitGame', null);
    }

    getLocalStorageItem(key) {
        return localStorage.getItem(key) || '';
    }

    setLocalStorageItem(key, value) {
        localStorage.setItem(key, value || '');
    }

    removeLocalStorageItem(key) {
        localStorage.removeItem(key);
    }

    clearLocalStorage() {
        localStorage.clear();
    }

    playAnimation(animation, { duration, delay } = {}) {
        duration = duration ?? getAnimationDuration(animation);
        delay = delay ?? getAnimationDelay(animation);
        let animationWrapper = new AnimationWrapper({ animation, duration, delay });

        this._animations.push(animationWrapper);
        this._refresh();

        return animationWrapper.promise;
    }

    playMultipleAnimations(animations) {
        return Promise.all(animations.map(animation => this.playAnimation(animation)));
    }

    terminateAllAnimations() {
        let result = Promise.all(this._animations.map(animation => animation.promise));

        for (let animation of this._animations) {
            this._terminateAnimation(animation);
        }

        this._animations = [];
        this._refresh();

        return result;
    }

    afterAllAnimationsFinish() {
        if (!this._animations.length) {
            return Promise.resolve();
        }

        return new Promise(resolve => this._afterAnimationsResolves.push(resolve));
    }

    refresh() {
        this._refresh();
    }

    getCurrentTime() {
        return this._currentTime;
    }

    registerImage(url, image) {
        this._renderer.registerImage(url, image);
    }

    renderTooltip(position, tooltip) {
        this._renderer.renderTooltip(position, tooltip);
    }

    log(message) {
        console.log(message);
    }
}

function getHandlerTargets(handler) {
    if (handler.targets) {
        return handler.targets;
    } else if (handler.target) {
        return [handler.target];
    } else {
        return [];
    }
}
globalThis.ALL_FUNCTIONS.push(Client);