
import { Box } from '../utils/box';
import { addAlphaToColor } from '../utils/colors';
import { ImageLoader } from '../image-loader';
import { HORIZONTAL_ALIGN_TO_OFFSET_X, VERTICAL_ALIGN_TO_OFFSET_Y, VERTICAL_ALIGN_TO_TEXT_BASELINE, TRIANGLE_POINTS, HORIZONTAL_HEXAGON_POINTS, VERTICAL_HEXAGON_POINTS, CURVE_POINTS, LINE_POINTS } from './constants';
import { formatText } from './text-formatting';
import { hashNumber, hashString } from '../utils/hash';

export class Renderer {
    constructor({ canvas, backgroundColor, virtualWidth, virtualHeight }) {
        this._canvas = canvas;
        this._backgroundColor = backgroundColor;
        this._virtualWidth = virtualWidth;
        this._virtualHeight = virtualHeight;
        this._ctx = canvas.getContext('2d');
        this._imageLoader = new ImageLoader();
        this._imageCache = new Map();
    }

    _getFormattedTextImageFromCache(parameters) {
        let { text, maxWidth, padding, textSize, textFont } = parameters;
        
        let hash = 0;
        hash = hashString(text, hash);
        hash = hashString(textFont, hash);
        hash = hashNumber(textSize, hash);
        hash = hashNumber(maxWidth, hash);
        hash = hashNumber(padding, hash);

        let image = this._imageCache.get(hash);

        if (!image) {
            image = formatText(parameters);
            this._imageCache.set(hash, image);
        }

        return image;
    }

    clearCache() {
        this._imageCache.clear();
    }

    registerImage(url, image) {
        this._imageLoader.register(url, image);
    }

    clear() {
        this._fill(Box.from(this._canvas), this._backgroundColor);
        this.setCursor(null);
    }

    render(graphics, highlightGraphics) {
        if (!graphics) {
            return Box.null();
        }

        if (highlightGraphics) {
            Object.assign(graphics, highlightGraphics);
        }

        let virtualToRealRatio = this._canvas.width / this._virtualWidth;
        let x = graphics.x || 0;
        let y = graphics.y || 0;
        let width = graphics.width || 0;
        let height = graphics.height || 0;
        let aspectRatio = graphics.aspectRatio || 0;
        let scale = graphics.scale || 1;
        let virtualBox = new Box(x, y, width, height)
            .stripToMatchAspectRatio(aspectRatio)
            .scale(scale);
        let box = virtualBox.fullScale(virtualToRealRatio).round();
        let smallestSide = Math.min(virtualBox.width, virtualBox.height);
        let borderWidth = getSize(graphics.borderWidth, smallestSide, virtualToRealRatio);
        let borderRadius = getSize(graphics.borderRadius, smallestSide, virtualToRealRatio);
        let backgroundColor = addAlphaToColor(graphics.backgroundColor, graphics.backgroundAlpha);
        let overlayColor = addAlphaToColor(graphics.overlayColor, graphics.overlayAlpha);
        let borderColor = addAlphaToColor(graphics.borderColor, graphics.borderAlpha);
        let image = this._imageLoader.get(graphics.image);
        let angle = graphics.angle || 0;
        let text = graphics.text?.toString();
        let formattedText = graphics.formattedText;
        let shape = graphics.shape || 'rectangle';
        let xMult = graphics.mirrorX ? -1 : 1;
        let yMult = graphics.mirrorY ? -1 : 1;

        this._ctx.save();

        if (angle) {
            let dx = box.x;
            let dy = box.y;

            this._ctx.translate(dx, dy);
            this._ctx.rotate(angle);
            this._ctx.translate(-dx, -dy);
        }

        if (backgroundColor || borderColor || overlayColor) {
            this._drawShape(shape, box, borderRadius, xMult, yMult);
            if (shape !== 'line') {
                this._ctx.clip();
            }
        }

        if (backgroundColor) {
            this._ctx.fillStyle = backgroundColor;
            this._ctx.fill();
        }

        if (image) {
            this._ctx.drawImage(image, box.x1, box.y1, box.width, box.height);
        }

        if (formattedText) {
            let textFont = graphics.textFont || 'Arial';
            let textSize = getSizeRounded(graphics.textSize, virtualBox.height, virtualToRealRatio);
            let textMargin = getSizeRounded(graphics.textMargin, virtualBox.height, virtualToRealRatio);
            let maxWidth = getSizeRounded(graphics.textMaxWidth || 0, smallestSide, virtualToRealRatio);
            let maxHeight = getSizeRounded(graphics.textMaxHeight || 0, smallestSide, virtualToRealRatio);
            let padding = Math.min(textMargin, borderRadius);
            let parameters = {
                text: formattedText,
                maxWidth,
                maxHeight,
                padding,
                textSize,
                textFont
            };
            let textImage = this._getFormattedTextImageFromCache(parameters);
            let x = Math.floor(box.x1 + (box.width - textImage.width) / 2);
            let y = Math.floor(box.y1 + (box.height - textImage.height) / 2);

            this._ctx.drawImage(textImage, x, y);
        }

        if (text) {
            let textSize = getSizeRounded(graphics.textSize, virtualBox.height, virtualToRealRatio);
            let textMargin = getSizeRounded(graphics.textMargin, box.height, virtualToRealRatio) + Math.round(borderWidth);
            let textFont = graphics.textFont || 'Arial';
            let textHorizontalAlign = graphics.textHorizontalAlign || 'center';
            let textVerticalAlign = graphics.textVerticalAlign || 'middle';
            let textColor = graphics.textColor || 'black';
            let textItalic = false;
            let textBold = false;
            if (textHorizontalAlign !== 'left') {
                textMargin = 0;
            }
            let textCursorIndex = graphics.textCursorIndex ?? -1;
            let textX = textMargin + box.x1 + HORIZONTAL_ALIGN_TO_OFFSET_X[textHorizontalAlign] * box.width;
            let textY = box.y1 + VERTICAL_ALIGN_TO_OFFSET_Y[textVerticalAlign] * box.height;

            this._ctx.font = `${textItalic ? 'italic ' : ''}${textBold ? 'bold ' : ''}${textSize}px "${textFont}"`;
            this._ctx.textAlign = textHorizontalAlign;
            this._ctx.textBaseline = VERTICAL_ALIGN_TO_TEXT_BASELINE[textVerticalAlign];
            this._ctx.fillStyle = textColor;
            this._ctx.fillText(text, textX, textY + 1);

            if (textCursorIndex > -1) {
                let textWidth = this._ctx.measureText(text).width;
                let subText = text.substring(0, textCursorIndex);
                let subTextWidth = this._ctx.measureText(subText).width;
                let cursorX = Math.round(textX - HORIZONTAL_ALIGN_TO_OFFSET_X[textHorizontalAlign] * textWidth + subTextWidth);
                let cursorY = Math.round(textY - VERTICAL_ALIGN_TO_OFFSET_Y[textVerticalAlign] * textSize);
                let cursorBox = Box.fromTopLeftAndSize(cursorX, cursorY, 1, textSize * 0.85);

                this._fill(cursorBox, textColor);
            }
        }

        if (overlayColor) {
            this._ctx.fillStyle = overlayColor;
            this._ctx.fill();
        }

        if (borderWidth && borderColor) {
            let lineDashLength = getSizeRounded(graphics.lineDashLength || 0, box.width, 1);
            let lineGapLength = getSizeRounded(graphics.lineGapLength || 0, box.width, 1);
            let w = borderWidth * (shape === 'line' ? 1 : 2);

            if (lineDashLength && lineGapLength) {
                this._ctx.setLineDash([lineDashLength, lineGapLength]);
            } else {
                this._ctx.setLineDash([]);
            }

            this._ctx.lineWidth = w;
            this._ctx.strokeStyle = borderColor;
            this._ctx.stroke();
        }

        this._ctx.restore();

        return virtualBox;
    }

    setCursor(skin) {
        this._canvas.style.cursor = skin || 'default';
    }

    renderImageOnCursor(position, imageUrl, scale) {
        // TODO: option to mirror the image if it ends up outside the screen

        let image = this._imageLoader.get(imageUrl);
        let biggestSideSize = Math.min(this._canvas.width, this._canvas.height) * scale;
        let width = biggestSideSize;
        let height = biggestSideSize;

        if (image.width > image.height) {
            height = height * image.height / image.width;
        } else {
            width = width * image.width / image.height;
        }

        let x = position.x;
        let y = position.y;

        this._ctx.drawImage(image, x, y, width, height);
    }

    // TODO: improve
    renderTooltip(position, tooltip) {
        if (!tooltip) {
            return;
        }

        let virtualToRealRatio = this._canvas.width / this._virtualWidth;
        let widthRatio = 0.2;
        let sizeRatio = 0.012;
        let image = this._getFormattedTextImageFromCache({
            text: tooltip,
            maxWidth: Math.round(this._canvas.width * widthRatio),
            padding: 6,
            textSize: Math.round(this._canvas.width * sizeRatio),
            textFont: 'Arial',
            backgroundColor: 'white',
            borderColor: 'black'
        });

        this._ctx.drawImage(image, position.x * virtualToRealRatio, position.y * virtualToRealRatio, image.width, image.height);
    }

    _fill(box, color) {
        if (color) {
            this._ctx.fillStyle = color;
            this._ctx.fillRect(box.x1, box.y1, box.width, box.height);
        }
    }

    _drawShape(shape, box, borderRadius, xMult, yMult) {
        this._ctx.beginPath();

        if (shape === 'rectangle') {
            if (borderRadius === 0) {
                this._ctx.rect(box.x1, box.y1, box.width, box.height);
            } else {
                let r  = borderRadius;
                let x1 = box.x1;
                let y1 = box.y1;
                let x2 = box.x2;
                let y2 = box.y2;

                this._ctx.moveTo(x1 + r, y1);
                this._ctx.lineTo(x2 - r, y1);
                this._ctx.quadraticCurveTo(x2, y1, x2, y1 + r);
                this._ctx.lineTo(x2, y2 - r);
                this._ctx.quadraticCurveTo(x2, y2, x2 - r, y2);
                this._ctx.lineTo(x1 + r, y2);
                this._ctx.quadraticCurveTo(x1, y2, x1, y2 - r);
                this._ctx.lineTo(x1, y1 + r);
                this._ctx.quadraticCurveTo(x1, y1, x1 + r, y1);
                this._ctx.closePath();
            }
        } else if (shape === 'line') {
            this._polygon(LINE_POINTS, box, xMult, yMult);
        } else if (shape === 'circle') {
            this._ctx.ellipse(box.x, box.y, box.width / 2, box.height / 2, 0, 0, Math.PI * 2);
        } else if (shape === 'triangle') {
            this._polygon(TRIANGLE_POINTS, box, xMult, yMult);
        } else if (shape === 'vertical-hexagon') {
            this._polygon(VERTICAL_HEXAGON_POINTS, box, xMult, yMult);
        } else if (shape === 'horizontal-hexagon') {
            this._polygon(HORIZONTAL_HEXAGON_POINTS, box, xMult, yMult);
        } else if (shape === 'curve') {
            this._polygon(CURVE_POINTS, box, xMult, yMult);
        }
    }

    _polygon(points, box, xMult, yMult) {
        let cx = box.x;
        let cy = box.y;
        let w = box.width * xMult;
        let h = box.height * yMult;

        this._ctx.beginPath();
        this._ctx.moveTo(cx + points[0][0] * w, cy + points[0][1] * h);

        for (let i = 1; i < points.length; ++i) {
            let [x, y] = points[i];

            this._ctx.lineTo(cx + x * w, cy + y * h);
        }

        if (points.length > 2) {
            this._ctx.closePath();
        }
    }
}

function getSize(value = 0, boxSize, virtualToRealSize) {
    if (typeof value === 'string' && value.endsWith('%')) {
        return boxSize * parseFloat(value) / 100 * virtualToRealSize;
    } else {
        return value * virtualToRealSize;
    }
}

function getSizeRounded(value, boxSize, virtualToRealSize) {
    return Math.round(getSize(value, boxSize, virtualToRealSize));
}
globalThis.ALL_FUNCTIONS.push(Renderer);