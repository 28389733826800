import { STAR_DIAMETER } from '../game-constants';
import { Star } from './star';

const POPULATION_GROWTH = 10;

export class NorthStar extends Star {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'north-star';
    static color = 'brown';
    static diameter = STAR_DIAMETER * 0.3;
    static description = `+${POPULATION_GROWTH} population growth to non-neutral inner planets.`;

    getPlanetPopulationGrowth({ planet }) {
        if (this.isStarOf(planet) && planet.owner) {
            return POPULATION_GROWTH;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(NorthStar);