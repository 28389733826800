export class QuadraticCurve {
    constructor(source, target, control) {
        this.p1 = source;
        this.p2 = target;
        this.cp = control;
    }

    static symetrical(source, target, m = 1) {
        let dx = (target.x - source.x) * m;
        let dy = (target.y - source.y) * m;
        let cx = (source.x + target.x) / 2;
        let cy = (source.y + target.y) / 2;
        let control = {
            x: cx + dy,
            y: cy - dx
        };

        return new QuadraticCurve(source, target, control);
    }

    at(t) {
        let u = 1 - t;
        let t2 = t * t;
        let u2 = u * u;
        let tu = 2 * t * u;
        let x = u2 * this.p1.x + tu * this.cp.x + t2 * this.p2.x;
        let y = u2 * this.p1.y + tu * this.cp.y + t2 * this.p2.y;

        return { x, y };
    }

    smoothAt(t) {
        if (t < 0.5) {
            t = ((t * 2) ** 2) / 2
        } else {
            // t = 0.5 + (((1 - t) * 2) ** 2) / 2
        }

        return this.at(t);
    }
}
globalThis.ALL_FUNCTIONS.push(QuadraticCurve);