import { BATTLEFIELD_HEIGHT, BATTLEFIELD_WIDTH } from '../game-constants';

export class Announcement {
    constructor({ text, duration, color }) {
        this.text = text;
        this.duration = duration;
        this.color = color;
    }

    render(t) {
        // const len = Math.min(this.text.length, Math.round(this.text.length * t * 4));
        const len = this.text.length;
        const text = this.text.substring(0, len);

        return {
            x: BATTLEFIELD_WIDTH / 2,
            y: BATTLEFIELD_HEIGHT / 2,
            width: BATTLEFIELD_WIDTH / 3,
            height: BATTLEFIELD_HEIGHT,
            aspectRatio: 6,
            text: text,
            textSize: '80%',
            textColor: this.color,
            backgroundColor: 'white',
            borderRadius: '15%',
            borderWidth: '3%',
            borderColor: this.color
        };
    }
}
globalThis.ALL_FUNCTIONS.push(Announcement);