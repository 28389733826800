import { Planet } from './planet';

const STARTING_POPULATION = 0;
const POPULATION_GROWTH = 5;

export class Crypso extends Planet {
    constructor(parameters) {
        super(parameters);
    }

    static id = 'crypso';
    static color = 'black';
    static description = `After this targets an allied planet with a move order, transfer the headquarters to this planet.`;

    onGameStart() {
        this.population += STARTING_POPULATION;
    }

    getPlanetPopulationGrowth({ planet }) {
        if (planet === this) {
            // return POPULATION_GROWTH;
        }
    }

    onCombatEnd({ planet }) {
        if (planet === this && this.order.type === 'move') {
            let targetIsStillUnderControl = this.order.target.owner === this.previousOwner;

            if (targetIsStillUnderControl) {
                let targetPrototype = Object.getPrototypeOf(this.order.target);
                let selfPrototype = Object.getPrototypeOf(this);

                // TODO: improve this insanity
                Object.setPrototypeOf(this.order.target, selfPrototype);
                Object.setPrototypeOf(this, targetPrototype);
            }

            return;

            let targetPopulation = planet.population;
            let targetOrder = planet.order;
            let targetRing = planet.ring;
            let targetIndex = planet.ring.bodies.indexOf(planet);
            let targetIndexOnRing = planet.indexOnRing;

            let selfPopulation = this.population;
            let selfOrder = this.order;
            let selfRing = this.ring;
            let selfIndex = this.ring.bodies.indexOf(this);
            let selfIndexOnRing = this.indexOnRing;

            selfRing.bodies[selfIndex] = planet;
            planet.ring = selfRing;
            planet.indexOnRing = selfIndexOnRing;
            planet.population = selfPopulation;
            planet.order = selfOrder;
            planet.order.source = planet;

            targetRing.bodies[targetIndex] = this;
            this.ring = targetRing;
            this.indexOnRing = targetIndexOnRing;
            this.population = targetPopulation;
            this.order = targetOrder;
            this.order.source = this;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Crypso);