import { Entity } from '../entity';
import { CX, CY } from '../game-constants';
import { formatDescription, formatId } from '../utils';

export class Technology extends Entity {
    constructor({ owner }) {
        super();
        this.owner = owner;
    }

    static description = null;

    getTooltip() {
        return [
            `#|${formatId(this.constructor.id)}`,
            ``,
            formatDescription(this.constructor.description)
        ].join('\n');
    }

    render(client) {
        let isSelf = this.owner === client.player;

        return {
            x: CX + 500 * (isSelf ? -1 : 1),
            y: CY - 380,
            width: 50,
            height: 50,
            image: `${this.constructor.id}.png`,
            borderColor: isSelf ? 'blue' : 'red',
            borderWidth: '4%',
            borderRadius: '20%'
        };
    }
}
globalThis.ALL_FUNCTIONS.push(Technology);