export function getObjectAtAddress(address, root, index = 0) {
    if (!address) {
        return undefined;
    } else if (index === address.length) {
        return root;
    } else if (Array.isArray(root)) {
        return getObjectAtAddress(address, root[address[index]], index + 1);
    } else if (root && typeof root === 'object') {
        let key = Object.keys(root)[address[index]];

        return getObjectAtAddress(address, root[key], index + 1);
    } else {
        return undefined;
    }
}

export function getObjectAddress(object, root) {
    if (!root) {
        return null;
    }

    let address = [];

    if (populateAddressStack(object, root, address, new Set())) {
        return address;
    } else {
        return null;
    }
}

function populateAddressStack(object, root, stack, marked) {
    if (marked.has(root)) {
        return false;
    } else if (object === root) {
        return true;
    } else if (Array.isArray(root)) {
        marked.add(root);

        for (let i = 0; i < root.length; ++i) {
            let value = root[i];

            stack.push(i);

            if (populateAddressStack(object, value, stack, marked)) {
                return true;
            }

            stack.pop();
        }
    } else if (root && typeof root === 'object') {
        marked.add(root);

        let values = Object.values(root);

        for (let i = 0; i < values.length; ++i) {
            let value = values[i];

            stack.push(i);

            if (populateAddressStack(object, value, stack, marked)) {
                return true;
            }

            stack.pop();
        }
    }
    
    return false;
}

export function replaceValuesWithAddresses(object, root) {
    let result = object;

    if (object && typeof object === 'object') {
        const addr = getObjectAddress(object, root);

        if (addr) {
            result = new CrossNetworkReference(addr);
        } else if (Array.isArray(object)) {
            result = new Array(object.length);

            for (let i = 0; i < object.length; ++i) {
                result[i] = replaceValuesWithAddresses(object[i], root);
            }
        } else if (object.constructor === Object) {
            result = {};

            for (let [key, value] of Object.entries(object)) {
                result[key] = replaceValuesWithAddresses(value, root);
            }
        }
    }

    return result;
}

export function replaceAddressesWithValues(object, root) {
    let result = object;

    if (object && typeof object === 'object') {
        if (object instanceof CrossNetworkReference) {
            result = getObjectAtAddress(object.address, root);
        } else if (Array.isArray(object)) {
            result = new Array(object.length);

            for (let i = 0; i < object.length; ++i) {
                result[i] = replaceAddressesWithValues(object[i], root);
            }
        } else if (object.constructor === Object) {
            result = {};

            for (let [key, value] of Object.entries(object)) {
                result[key] = replaceAddressesWithValues(value, root);
            }
        }
    }

    return result;
}

export class CrossNetworkReference {
    constructor(address) {
        this.address = address;
    }
}
globalThis.ALL_FUNCTIONS.push(CrossNetworkReference);